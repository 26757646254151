import { API, graphqlOperation } from "aws-amplify";
import { GraphQLOptions, GraphQLResult } from "@aws-amplify/api-graphql";

const createGQLDataFetcher = <T>(
  query: GraphQLOptions,
  newVariables?: (res: GraphQLResult<T>) => GraphQLOptions["variables"]
) => {
  let nextVariables: GraphQLOptions["variables"] = query.variables;

  return async (): Promise<T | null> => {
    if (!nextVariables) {
      return null;
    }
    const currentResult = await (API.graphql({
      ...query,
      variables: nextVariables,
    }) as unknown as Promise<GraphQLResult<T>>);

    if (newVariables) {
      nextVariables = newVariables(currentResult);
    } else {
      nextVariables = undefined;
    }

    return currentResult.data || null;
  };
};

export { createGQLDataFetcher };
