/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import React, { Component } from "react";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import * as slugify from "slugify";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

class DelItem extends Component {
  itemName = "";
  componentDidUpdate(prevProps) {
    if (prevProps.formError !== this.props.formError) {
      this.updateAndNotify();
    }
    if (prevProps.disabled !== this.props.disabled) {
      this.updateAndNotify();
    }
    if (prevProps.referenceValue !== this.props.referenceValue) {
      this.updateAndNotify();
    }
  }
  updateAndNotify = () => {
    this.setState({
      disabled: this.props.disabled,
      referenceValue: this.props.referenceValue,
      formError: this.props.formError,
    });
  };
  constructor(props) {
    super(props);
    this.itemName = props.itemName ? props.itemName : "Item";
    this.state = {
      open: false,
      onSubmit: props.onSubmit,
      fields: props.fields,
      disabled: props.disabled,
      referenceValue: props.referenceValue,
      errors: {},
      formError: props.formError,
    };
    this.props.fields.forEach((f) => {
      this.state[f.id] = "";
      this.state.errors[f.id] = false;
    });
  }
  handleSubmit = async () => {
    let errorState = false;
    this.state.fields.forEach((field) => {
      if (
        !this.changeHandler(field.name, {
          target: document.getElementById(field.name),
        })
      )
        errorState = true;
    });
    if (errorState) return false;
    if (await this.state.onSubmit(this.state)) {
      if (!this.state.formError) {
        this.handleClose();
        this.setState({ errors: {} });
      }
    }
  };
  handleCancel = () => {
    this.setState({ errors: {} });
    this.setState({ formError: false });
    this.setState({ open: false });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    if (!this.state.formError) this.setState({ open: false });
  };
  changeHandler = (name, event) => {
    let returnValue = true;
    let checkVal = "value";
    if (event.target.type === "checkbox") checkVal = "checked";

    this.setState({
      [name]: event.target.value,
    });
    let required = this.state.fields.find((x) => x.id === name).required;
    if (event.target[checkVal] === "" || event.target[checkVal] === false) {
      if (required) {
        this.setState({
          errors: {
            ...this.state.errors,
            [name]: true,
          },
        });
        returnValue = false;
      }
    } else {
      switch (event.target.type) {
        case "input": {
          this.setState({
            errors: {
              ...this.state.errors,
              [name]: false,
            },
          });
          returnValue = true;
          break;
        }
        case "checkbox":
          {
            if (event.target[checkVal] === true) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  [name]: false,
                },
              });
              returnValue = true;
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  [name]: true,
                },
              });
              returnValue = false;
            }
          }
          break;
      }
    }
    return returnValue;
  };
  handleChange = (name) => (event) => {
    return this.changeHandler(name, event);
  };
  render() {
    const {
      open,
      fields,
      referenceValue,
      disabled,
      errors,
      formError,
      itemName,
    } = this.state;
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Button
          mini="true"
          color="inherit"
          aria-label="Add"
          onClick={this.handleClickOpen}
          disabled={disabled}
        >
          <DeleteOutlinedIcon />
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Delete an existing {this.itemName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            {fields.map((f) => {
              let key = `${f.id}_${slugify(f.label)}`;
              let fieldValue = undefined;
              if (f.reference) {
                fieldValue = referenceValue;
              }
              const fieldElement = () => {
                if (f.type !== "checkbox") {
                  return (
                    <>
                      <TextField
                        editable={f.editable}
                        multiline={f.multiline}
                        disabled={f.disabled}
                        style={{ ...f.style, marginRight: 10 }}
                        id={f.id}
                        name={f.id}
                        key={key}
                        type={f.type}
                        value={fieldValue}
                        onChange={this.handleChange(`${f.id}`)}
                      />
                    </>
                  );
                } else {
                  return (
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            label={f.label}
                            id={f.id}
                            name={f.id}
                            key={key}
                            type="checkbox"
                            value={fieldValue}
                            onChange={this.handleChange(`${f.id}`)}
                          />
                        }
                        label={f.label}
                      />
                    </FormGroup>
                  );
                }
              };
              return (
                <FormControl key={key} error={errors[f.id]}>
                  {fieldElement()}
                  {errors[f.id] && (
                    <FormHelperText>This is required!</FormHelperText>
                  )}
                </FormControl>
              );
            })}
          </DialogContent>
          <FormControl error={formError}>
            {formError && (
              <FormHelperText>Error creating {itemName}</FormHelperText>
            )}
          </FormControl>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Delete {this.itemName}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default DelItem;
