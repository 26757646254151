/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import React, { Component } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import * as slugify from "slugify";
import { v1 as uuid } from "uuid";

class AddItem extends Component {
  itemName = "";
  componentDidUpdate(prevProps) {
    if (prevProps.formError !== this.props.formError) {
      this.updateAndNotify();
    }
    if (prevProps.disabled !== this.props.disabled) {
      this.updateAndNotify();
    }
    if (prevProps.referenceValue !== this.props.referenceValue) {
      this.updateAndNotify();
    }
  }
  updateAndNotify = () => {
    this.setState({
      disabled: this.props.disabled,
      referenceValue: this.props.referenceValue,
      formError: this.props.formError,
    });
  };
  constructor(props) {
    super(props);
    this.itemName = props.itemName ? props.itemName : "Item";
    this.state = {
      fields: props.fields,
      open: false,
      onSubmit: props.onSubmit,
      disabled: props.disabled,
      referenceValue: props.referenceValue,
      errors: [],
      resetErrors: [],
      formError: props.formError,
    };
    props.fields.forEach((f) => {
      if (f.value) {
        this.state[f.id] = f.value;
      } else {
        if (f.reference) {
          this.state[f.id] = this.state.referenceValue;
        } else {
          this.state[f.id] = "";
        }
      }
      this.state.resetErrors = [
        ...this.state.resetErrors,
        { name: f.id, error: false },
      ];
      this.state.errors = [...this.state.errors, { name: f.id, error: false }];
    });
  }
  errorCheck = (field) => {
    if (this.state[field] === "" || this.state[field] === undefined) {
      return true;
    }
  };
  handleSubmit = async () => {
    let errorState = false;
    this.state.fields.forEach((field) => {
      if (document.getElementById(field.name).value === "" && field.required) {
        errorState = true;
        this.setState({
          errors: [
            {
              name: field.name,
              error: true,
            },
            ...this.state.errors,
          ],
        });
      }
    });
    if (errorState) return false;

    this.setState({ id: uuid() });
    if (await this.state.onSubmit(this.state)) {
      if (!this.state.formError) {
        this.handleClose();
        this.setState({ errors: this.state.resetErrors });
      }
    }
  };
  handleCancel = () => {
    this.setState({ errors: this.state.resetErrors });
    this.setState({ formError: false });
    this.setState({ open: false });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    if (!this.state.formError) this.setState({ open: false });
  };
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    if (event.target.value === "") {
      this.setState({
        errors: [
          {
            name: name,
            error: true,
          },
          ...this.state.errors,
        ],
      });
    }
  };
  render() {
    const {
      open,
      referenceValue,
      disabled,
      errors,
      formError,
      fields,
      itemName,
    } = this.state;
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Button
          mini="true"
          color="inherit"
          aria-label="Add"
          onClick={this.handleClickOpen}
          disabled={disabled}
        >
          <AddIcon />
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Add a New {this.itemName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            {fields.map((f) => {
              let errorVal = errors.find((x) => x.name === f.id).error;
              let key = `${f.id}_${slugify(f.label)}`;
              let fieldValue = undefined;
              if (f.reference) {
                fieldValue = referenceValue;
              } else {
                if (f.value) {
                  fieldValue = f.value;
                }
              }
              return (
                <FormControl key={key} error={errorVal}>
                  <TextField
                    label={f.label}
                    style={{ marginRight: 10 }}
                    id={f.id}
                    name={f.id}
                    key={key}
                    type={f.type}
                    value={fieldValue}
                    onChange={this.handleChange(`${f.id}`)}
                    helperText={f.helperText}
                  />
                  {errorVal && (
                    <FormHelperText>This is required!</FormHelperText>
                  )}
                </FormControl>
              );
            })}
          </DialogContent>
          <FormControl error={formError}>
            {formError && (
              <FormHelperText>Error creating {itemName}</FormHelperText>
            )}
          </FormControl>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default AddItem;
