import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import Logo from "./logo_clipped_rev_1.png";

// eslint-disable-next-line
export const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      minHeight: "100vh",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    paper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    typo: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    title: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontWeight: "bold",
      fontSize: "large",
    },
    grid: {
      textAlign: "center",
    },
    warning: {
      color: "red",
      position: "fixed",
      top: "90vh",
      left: "10%",
      right: "10%",
      fontWeight: "bold",
    },
    button: {
      margin: theme.spacing(3),
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    },
  });

interface AuthWrapperProps {
  title: string;
  subtitle: string;
}
export const AuthWrapper: FunctionComponent<AuthWrapperProps> = ({
  children,
  title,
  subtitle,
}) => {
  const classes = makeStyles(styles)();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid className={classes.grid} item xs={11} lg={6} md={10} sm={10}>
        <img
          className={classes.logo}
          src={Logo}
          alt="CQA Logo"
          style={{ width: "250px", height: "250px" }}
        />
        <Card variant="outlined" className={classes.paper}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.typo}>{subtitle}</Typography>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
          >
            {children}
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};
