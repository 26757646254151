import moment from "moment-timezone";
import { Airline, defaultAirline } from "./Airline";
import { dataFilterer } from "./util";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gvbRow = (airlines: Airline[], form: any) => {
  if (!dataFilterer(form)) {
    return "";
  }
  const header = form.id;
  const airline = (
    airlines.find((a) => a.id === form.CustomerForm.Airline) || defaultAirline
  ).abbreviation;
  const flightNo = (form.CustomerForm.Flight || "")
    .split("/")[0]
    .padStart(4, "0");
  const chartered = form.CustomerForm.Airline === "PV" ? "Y" : "N";
  const language = form.CustomerForm.Language || "";
  const isTransitStatus =
    form.CustomerForm.StatusField.indexOf("transit") !== -1;
  const isTerminatingStatus =
    form.CustomerForm.StatusField.indexOf("terminating") !== -1;
  const isCrewStatus = form.CustomerForm.StatusField.indexOf("crew") !== -1;
  const transit =
    isCrewStatus && (isTransitStatus || isTerminatingStatus)
      ? 2
      : isTransitStatus
      ? 1
      : "";
  const totalFamily = form.CustomerForm.FamilyMembersField;
  const japan =
    form.CustomerForm.CountryOfOriginField === "15"
      ? form.CustomerForm.CountryOfOriginFieldCity.substring(0, 2)
      : "";
  const korea =
    form.CustomerForm.CountryOfOriginField === "16"
      ? form.CustomerForm.CountryOfOriginFieldCity.substring(0, 2)
      : "";
  const china =
    form.CustomerForm.CountryOfOriginField === "17"
      ? form.CustomerForm.CountryOfOriginFieldCity.substring(0, 2)
      : "";
  const taiwan =
    form.CustomerForm.CountryOfOriginField === "18"
      ? form.CustomerForm.CountryOfOriginFieldCity.substring(0, 2)
      : "";
  const russia =
    form.CustomerForm.CountryOfOriginField === "19"
      ? form.CustomerForm.CountryOfOriginFieldCity.substring(0, 2)
      : "";
  const country =
    form.CustomerForm.CountryOfOriginField === "Other"
      ? "14"
      : form.CustomerForm.CountryOfOriginField;
  const visitorStatuses: Record<string, number> = {
    resident: 3,
    intended_resident: 2,
    visitor: 1,
  };
  const visitorResident = visitorStatuses[form.CustomerForm.StatusField] || "";
  const stayLengths: Record<string, string> = {
    layover: "1",
    day_trip: "2",
    "1": "3",
    "2": "4",
    "3": "5",
    "4": "6",
    "5": "7",
    "6": "8",
    "7": "9",
    "11": "10",
    "16": "11",
    month: "12",
    year: "13",
  };
  const lengthInGuam =
    form.VisitorSpecificForm && form.VisitorSpecificForm.LengthOfStayField
      ? stayLengths[form.VisitorSpecificForm.LengthOfStayField].padStart(2, "0")
      : "";
  const tripsToGuam =
    (form.VisitorSpecificForm && form.VisitorSpecificForm.TripToGuamField) ||
    "";
  const yesNos: Record<string, number> = {
    yes: 1,
    no: 2,
    Yes: 1,
    No: 2,
  };
  const travelArrangementOnline =
    form.VisitorSpecificForm && form.VisitorSpecificForm.onlineBooking
      ? yesNos[form.VisitorSpecificForm.onlineBooking]
      : "";
  const travelArrangements: Record<string, number> = {
    agent: 1,
    website: 2,
    direct: 3,
  };

  const travelArrangedThrough =
    form.VisitorSpecificForm && form.VisitorSpecificForm.TravelArrangementsField
      ? travelArrangements[form.VisitorSpecificForm.TravelArrangementsField]
      : "";
  const stayWhileInGuam =
    [
      "hotel",
      "rental",
      "ship",
      "friends",
      "timeshare",
      "bandb",
      "military",
      "other",
    ].reduce((code: string, option: string, index: number) => {
      if (
        form.VisitorSpecificForm &&
        form.VisitorSpecificForm[`${option}Field`]
      ) {
        return `${code}${index + 1}`;
      } else {
        return code;
      }
    }, "") || "";

  const tripReasons: Record<string, string> = {
    pleasure: "01",
    married: "02",
    honeymoon: "03",
    conference: "04",
    incentive: "05",
    business: "06",
    friends: "07",
    military: "08",
    school: "09",
    sports: "10",
    Medical: "11",
    Other: "12",
  };

  const primaryReason =
    form.VisitorSpecificForm && form.VisitorSpecificForm.TripReasonField
      ? tripReasons[form.VisitorSpecificForm.TripReasonField]
      : "";

  const toGenderCode = (gender: "m" | "f" | undefined) =>
    gender ? { m: 1, f: 2 }[gender] : "";
  const toAgeGroup = (year: string, month: string, day: string) => {
    if (year && month && day) {
      const birthday = moment(`${day} ${month} ${year}`, "D MMMM YYYY");
      const age = Math.abs(Number(moment().diff(birthday, "years")));
      const groups = [
        { min: 0, max: 12, code: 1 },
        { min: 13, max: 17, code: 2 },
        { min: 18, max: 24, code: 3 },
        { min: 25, max: 39, code: 4 },
        { min: 40, max: 49, code: 5 },
        { min: 50, max: 59, code: 6 },
        { min: 60, max: 1200, code: 7 },
      ];

      const group = groups.find(
        (group) => group.min <= age && group.max >= age
      );
      return group ? group.code : "";
    } else {
      return "";
    }
  };

  const getSuffix = (i: number) => i || "";
  function getFormData<T>(object: { [prop: string]: T }) {
    return (field: string, index: number) => {
      return object[`${field}${getSuffix(index)}`];
    };
  }
  const personData = getFormData<string>(form.CustomerForm);
  const genderGetter = getFormData<"m" | "f" | undefined>(form.CustomerForm);
  const genders = [...Array(8)].map((_, i) =>
    toGenderCode(genderGetter("Gender", i))
  );
  const ages = [...Array(8)].map((_, i) =>
    toAgeGroup(
      personData("DOBYearField", i),
      personData("DOBMonthField", i),
      personData("DOBDayField", i)
    )
  );
  return `"${header}","${airline}","${flightNo}","${chartered}","${language}","${transit}","${totalFamily}","${japan}","${korea}","${china}","${taiwan}","${russia}","${country}","${visitorResident}","${lengthInGuam}","${tripsToGuam}","${travelArrangementOnline}","${travelArrangedThrough}","${stayWhileInGuam}","${primaryReason}","${genders[0]}","${ages[0]}","${genders[1]}","${ages[1]}","${genders[2]}","${ages[2]}","${genders[3]}","${ages[3]}","${genders[4]}","${ages[4]}","${genders[5]}","${ages[5]}","${genders[6]}","${ages[6]}","${genders[7]}","${ages[7]}"`;
};
