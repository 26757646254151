interface AgentAction {
  action: "F" | "S";
  agent: string;
  createdAt: Date;
  location: string;
  secondary: string;
}

const dataFilterer = (f: { agentActions: { items: AgentAction[] } }) =>
  f.agentActions.items.find(
    (a) =>
      (a.location === "Primary" ||
        a.location === "Secondary" ||
        a.location === "Primary " ||
        a.location === "Exit") &&
      a.action === "F"
  ) !== undefined;

export { dataFilterer };
