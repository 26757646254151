import moment from "moment-timezone";
import { Airline, defaultAirline } from "./Airline";
import * as config from "../../config.json";
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const partyRow = (airlines: Airline[], form: any) => {
  const header = form.QRCodeBase;
  const partyID = form.PartyID;
  const arrivalDate = moment(form.ArrivalDate)
    .tz(config.timezone)
    .format("yyyy-MM-DD");

  // Casting form.agentComments.items as array, then filtering the array whene agentToTraveller is true,
  // then creating a map of comments and then joining by comma
  const agentComments = ((form.agentComments as { items: [] }).items as [])
    .filter((item: { agentToTraveller: boolean }) => item.agentToTraveller)
    .map((item: { comment: string }) => item.comment)
    .join(", ");

  const airline = (
    airlines.find((a) => a.id === form.CustomerForm.Airline) || defaultAirline
  ).abbreviation;

  const flightNo = form.CustomerForm.Flight
    ? form.CustomerForm.Flight.split("/")[0].padStart(4, "0")
    : "";
  const status = form.CustomerForm.StatusField;

  const totalFamily = form.CustomerForm.FamilyMembersField;
  const countriesOfOrigin: Record<string, string> = {
    "01": "Guam",
    "13": "USA",
    "15": "Japan",
    "16": "Korea",
    "17": "China",
    "18": "Taiwan",
    "02": "Australia",
    "03": "CNMI",
    "04": "Europe",
    "05": "FSM",
    "06": "Hong Kong",
    "07": "India",
    "08": "Malaysia",
    "09": "Palau",
    "10": "Philippines",
    "11": "RMI",
    "12": "Singapore",
    "19": "Russia",
    Other: "Other",
  };

  const CountryOfOrigin = form.CustomerForm.OriginatingFromField;
  const CityOfOrigin = form.CustomerForm.OriginatingFromCityField || "";

  const ResidenceCity = form.CustomerForm.CountryOfOriginFieldCity
    ? form.CustomerForm.CountryOfOriginFieldCity
    : "";

  const ResidenceCountry = form.CustomerForm.CountryOfOriginField
    ? countriesOfOrigin[form.CustomerForm.CountryOfOriginField]
    : "";

  const stayLengths: Record<string, string> = {
    layover: "Just a few hours layover",
    day_trip: "For a day trip",
    "1": "1 night",
    "2": "2 nights",
    "3": "3 nights",
    "4": "4 nights",
    "5": "5 nights",
    "6": "6 nights",
    "7": "7 - 10 nights",
    "11": "11 - 15 nights",
    "16": "16 - 30 nights",
    month: "1 - 3 months",
    year: "3 months - 1 year",
  };
  const lengthInGuam =
    form.VisitorSpecificForm && form.VisitorSpecificForm.LengthOfStayField
      ? stayLengths[form.VisitorSpecificForm.LengthOfStayField].padStart(2, "0")
      : "";
  const addressOnGuam = form.CustomerForm.AddressOnGuam;
  const finalDestination =
    (form.TransitForm && form.TransitForm.TransitFinalDestination) || "";
  const tripsToGuam =
    (form.VisitorSpecificForm && form.VisitorSpecificForm.TripToGuamField) ||
    "";
  const yesNos: Record<string, number> = {
    yes: 1,
    no: 2,
    Yes: 1,
    No: 2,
  };

  const travelArrangementOnline =
    form.VisitorSpecificForm && form.VisitorSpecificForm.onlineBooking
      ? yesNos[form.VisitorSpecificForm.onlineBooking]
      : "";
  const travelArrangements: Record<string, number> = {
    agent: 1,
    website: 2,
    direct: 3,
  };

  const travelArrangedThrough =
    form.VisitorSpecificForm && form.VisitorSpecificForm.TravelArrangementsField
      ? travelArrangements[form.VisitorSpecificForm.TravelArrangementsField]
      : "";
  const stayWhileInGuam = form.VisitorSpecificForm
    ? [
        "hotel",
        "rental",
        "ship",
        "friends",
        "timeshare",
        "bandb",
        "military",
        "other",
      ].reduce(
        (code: Record<string, unknown>, option: string) => ({
          ...code,
          [option]: form.VisitorSpecificForm[`${option}Field`] ? 1 : 0,
        }),
        {}
      )
    : {
        hotel: "",
        rental: "",
        ship: "",
        friends: "",
        timeshare: "",
        bandb: "",
        military: "",
        other: "",
      };

  const reasons: Record<string, string> = {
    pleasure: "Pleasure/Vacation",
    married: "To get married",
    honeymoon: "Honeymoon",
    conference: "Meeting/Conference",
    incentive: "Incentive Trip",
    business: "Other Business",
    friends: "Visiting friends or relatives",
    military: "Government or Military",
    school: "School Trip",
    sports: "Sports Event",
    Medical: "Medical",
    Other: "Other",
  };

  let reasonField;
  switch (status) {
    case "visitor":
      reasonField =
        form.VisitorSpecificForm && form.VisitorSpecificForm.TripReasonField;
      break;
    case "intended_resident":
      reasonField =
        form.IntendedResident && form.IntendedResident.TripReasonField;
      break;
    case "resident":
      reasonField = form.ResidentForm && form.ResidentForm.ReturningReason;
      break;
    default:
      reasonField = null;
      break;
  }

  const primaryReason = reasonField ? reasons[reasonField] : "";
  const email =
    (form.VisitorSpecificForm && form.VisitorSpecificForm.EmailField) || "";
  const phone =
    (form.VisitorSpecificForm && form.VisitorSpecificForm.PhoneField) || "";

  const packingList = [
    "Substances",
    "Firearms",
    "Explosives",
    "Animals",
    "AnimalProducts",
    "Plants",
    "Soil",
    "ServiceAnimal",
    "Cigarettes",
    "Alcohol",
  ].reduce(
    (code: Record<string, unknown>, option: string) => ({
      ...code,
      [option]: form.PackingList[`${option}Field`] ? 1 : 0,
    }),
    {}
  );

  const declaredGood = form.DeclarationForm.DeclarationForm || "";
  const monetaryDeclaration = form.CurrencyVisitorCrewForm["10KField"] ? 1 : 0;

  const resale = form.ResaleForm.ResaleField ? 1 : 0;
  const remain = form.ResaleForm.RemainField || "";
  const certification = form.ConsentForm.ConsentField ? 1 : 0;

  const language = form.CustomerForm.Language || "";

  /**
   * If number of agent actions < 7, add empty actions
   * This is required because:
   * a) we have AGENT_0 to AGENT_6 columns in CSV
   * b) we want Party ID and Agent Comments at the end of the CSV file
   * Refer LAN-124 for more details
   */
  const emptyAgentAction: Record<string, string> = {
    agent: "",
    location: "",
    action: "",
    secondary: "",
    createdAt: "9999-12-31T00:00:00.000Z",
  };

  while (form.agentActions.items.length < 7) {
    form.agentActions.items.push(emptyAgentAction);
  }

  const actions = form.agentActions.items
    .sort((a: Record<"createdAt", string>, b: Record<"createdAt", string>) =>
      a.createdAt.localeCompare(b.createdAt)
    )
    .map(
      (action: Record<string, string>) =>
        `"${action.agent}","${action.location}","${
          action.action
        }","${action.secondary.replace(",", ";")}"`
    )
    .filter((_: unknown, i: number) => i < 7)
    .join(",");

  return `"${header}","${arrivalDate}","${airline}","${flightNo}","${CountryOfOrigin}","${CityOfOrigin}","${ResidenceCountry}","${ResidenceCity}","${totalFamily}","${status}","${lengthInGuam}","${addressOnGuam}","${finalDestination}","${tripsToGuam}","${travelArrangementOnline}","${travelArrangedThrough}","${stayWhileInGuam.hotel}","${stayWhileInGuam.rental}","${stayWhileInGuam.ship}","${stayWhileInGuam.friends}","${stayWhileInGuam.timeshare}","${stayWhileInGuam.bandb}","${stayWhileInGuam.military}","${stayWhileInGuam.other}","${primaryReason}","${email}","${phone}","${packingList.Substances}","${packingList.Firearms}","${packingList.Explosives}","${packingList.Animals}","${packingList.AnimalProducts}","${packingList.Plants}","${packingList.Soil}","${packingList.ServiceAnimal}","${packingList.Cigarettes}","${packingList.Alcohol}","${declaredGood}","${monetaryDeclaration}","${resale}","${remain}","${certification}","${language}",${actions},"${partyID}","${agentComments}"`;
};
