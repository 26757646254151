/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */

const TextField = (message, name = null) => {
  let field = {
    component: "Text",
    text: message,
  };
  if (name) field.name = name;
  return field;
};

export default TextField;
