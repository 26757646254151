import { v4 as uuid } from "uuid";
import * as FileSaver from "file-saver";
import * as cms from "../lib/active_cms";
import Observable from "zen-observable-ts";
import { Airline } from "./reportBuilders";
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { Agency, AuthContextValue } from "../contexts/AuthContext";
import { BasePage } from "./BasePage";
import { API, graphqlOperation } from "aws-amplify";
import { customerResponsesByArrivalDate } from "../graphql/queries";
import { createReportExtract } from "../graphql/mutations";
import {} from "../graphql/subscriptions";

import moment, { Moment } from "moment-timezone";

import { GraphQLResult } from "@aws-amplify/api-graphql";
import * as config from "../config.json";
import { CustomerResponse, CustomerResponsesByArrivalDateQuery } from "../API";
import {
  createGQLDataFetcher,
  createReportGenerator,
} from "./report-generator";
import { ReportType } from "./report-generator/types";

import { useMutation } from "react-query";

const normaliseProgress = (value: number, max = 100, min = 0) =>
  ((value - min) * 100) / (max - min);

const onCreateReportExtract = /* GraphQL */ `
  subscription OnCreateReportExtract {
    onCreateReportExtract {
      id
      exportedAt
      user
      userId
      agency
      type
      exportFrom
      exportTo
      createdAt
      updatedAt
    }
  }
`;
const reportExtracts = /* GraphQL */ `
  query ReportExtractsByAgency(
    $agency: String
    $exportedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportExtractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportExtractsByAgency(
      agency: $agency
      exportedAt: $exportedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exportedAt
        user
        userId
        agency
        type
        exportFrom
        exportTo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

type transformer<T> = (val: string) => T;
function conditionallyTransform<T>(
  transformer: transformer<T>
): transformer<T> {
  return (value: string): T => {
    if (typeof value === "string") {
      return transformer(value);
    } else {
      return value;
    }
  };
}

const transformDate: (value: string) => Date = (value: unknown) =>
  new Date(value as string);

const condTransformDate: (value: string) => Date =
  conditionallyTransform(transformDate);
const condTransformJSON: (value: string) => Record<string, unknown> =
  conditionallyTransform(JSON.parse);

const transformers: {
  [key: string]: (value: string) => Date | Record<string, unknown>;
} = {
  ConsentForm: condTransformJSON,
  CurrencyVisitorCrewForm: condTransformJSON,
  CustomerForm: condTransformJSON,
  DeclarationForm: condTransformJSON,
  HealthForm1: condTransformJSON,
  HealthForm2: condTransformJSON,
  HealthForm3: condTransformJSON,
  HealthForm4: condTransformJSON,
  HealthForm5: condTransformJSON,
  HealthForm6: condTransformJSON,
  HealthForm7: condTransformJSON,
  HealthForm8: condTransformJSON,
  IntendedResident: condTransformJSON,
  PackingList: condTransformJSON,
  ResaleForm: condTransformJSON,
  ResidentForm: condTransformJSON,
  TransitForm: condTransformJSON,
  VisitorSpecificForm: condTransformJSON,
  createdAt: condTransformDate,
  updatedAt: condTransformDate,
  ArrivalDate: condTransformDate,
};
const defaultTransform: transformer<unknown> = (val: unknown) => val;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    table: {
      //   minWidth: 650,
    },
    typeRadioGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    downloadType: {
      margin: "0 auto",
    },
    divider: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  })
);

interface Download {
  id: string;
  exportedAt: Date;
  export_from: Date;
  export_to: Date;
  userName: string;
  agency: Agency;
  downloadType: DownloadType;
}

enum DownloadType {
  PARTY = "Party",
  TRAVELLER = "Traveller",
  BOTH = "Both",
  ALL = "All",
}

interface GQLDownload {
  agency: Agency;
  createdAt: string;
  exportFrom: string;
  exportTo: string;
  exportedAt: string;
  id: string;
  type: DownloadType;
  updatedAt: string;
  user: string;
  userId: string;
}

const createDownload = (item: GQLDownload): Download => ({
  id: item.id,
  exportedAt: new Date(item.exportedAt),
  export_from: new Date(item.exportFrom),
  export_to: new Date(item.exportTo),
  userName: item.user,
  agency: item.agency,
  downloadType: item.type,
});

const downloadMap: { [key in DownloadType]: Array<ReportType> } = {
  Party: ["Party"],
  Traveller: ["Traveller"],
  Both: ["Party", "Traveller"],
  All: ["Party", "Traveller", "Health", "GVB"],
};
const identifyReportTypes = (
  agency: Agency,
  downloadType: DownloadType
): Array<ReportType> => {
  if (agency === "GVB") {
    return ["GVB"];
  }
  if (agency === "Health") {
    return ["Health"];
  }
  if (agency === "CQA" || agency === "NDS") {
    return downloadMap[downloadType];
  }
  return [];
};

type ExportStatusUpdateCallback = (current: number, of: number) => void;

interface ExportMetadata {
  auth: AuthContextValue;
  downloadType: DownloadType;
  dateRange: DateRange;
  airlines: Airline[];
  statusCallback?: ExportStatusUpdateCallback;
}

interface ExportResult {
  startDate: Date;
  endDate: Date;
  filePrefix: string;
  blobURL: string | null;
  fileExtension: string | null;
}

const exportReport = async ({
  auth,
  dateRange,
  downloadType,
  airlines,
  statusCallback,
}: ExportMetadata): Promise<ExportResult> => {
  const startDate = moment
    .utc(dateRange.startDate || new Date())
    .tz(config.timezone)
    .startOf("day");
  const endDate = moment
    .utc(dateRange.endDate || new Date())
    .tz(config.timezone)
    .startOf("day");

  const filePrefix = `${startDate.format("yyyy-MM-DD")}_${endDate.format(
    "yyyy-MM-DD"
  )}`;
  const results: ExportResult = {
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
    filePrefix,
    blobURL: null,
    fileExtension: null,
  };

  // get dates in range
  const queryDates: Array<moment.Moment> = [];
  for (
    let currentDate = startDate;
    !moment(currentDate).isAfter(endDate);
    currentDate = moment(currentDate).add(1, "day")
  ) {
    queryDates.push(currentDate);
  }

  // make calls for each date
  const searchDates = new Array<moment.Moment>(...queryDates);
  let date = searchDates.shift();
  const initialQuery = graphqlOperation(customerResponsesByArrivalDate, {
    ArrivalDate: date ? date.toISOString() : undefined,
  });

  let currentDateNumber = 0;
  const completeDateNumber = endDate.diff(startDate, "days");
  const dataFetcher = createGQLDataFetcher<CustomerResponsesByArrivalDateQuery>(
    initialQuery,
    (res) => {
      let nextToken = res.data?.customerResponsesByArrivalDate?.nextToken;
      if (!nextToken) {
        const nextDate = searchDates.shift();
        if (!nextDate) {
          return undefined;
        }
        nextToken = undefined;
        date = nextDate;
        currentDateNumber++;
        statusCallback && statusCallback(currentDateNumber, completeDateNumber);
      }
      return {
        ArrivalDate: date ? date.toISOString() : undefined,
        nextToken,
      };
    }
  );

  const dataCleanser = (data: CustomerResponse) => {
    return Object.entries(data).reduce(
      (result, [key, value]) => ({
        ...result,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [key]: (transformers[key] || defaultTransform)(value),
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as any
    );
  };

  const reportTypes = identifyReportTypes(auth.agency, downloadType);
  const reportGenerator = await createReportGenerator(airlines, reportTypes);

  let currentData: CustomerResponsesByArrivalDateQuery | null = null;
  while ((currentData = await dataFetcher()) !== null) {
    if (!currentData.customerResponsesByArrivalDate) {
      continue;
    }
    const items = currentData.customerResponsesByArrivalDate.items;
    if (!items) {
      continue;
    }
    const itemLength = items.length;
    for (let i = 0; i < itemLength; i++) {
      const current = items[i] as CustomerResponse;
      const cleansedData = dataCleanser(current);
      const result = await reportGenerator.write(cleansedData);
      if (!result) {
        console.log("Error processing file??");
      }
    }
  }
  const fileExport = await reportGenerator.complete(filePrefix);
  if (!fileExport) {
    return results;
  }
  const { blobURL, extension } = fileExport;

  // FileSaver.saveAs(blob, filename);
  if (blobURL) {
    // send back to GQL
    await API.graphql(
      graphqlOperation(createReportExtract, {
        input: {
          id: uuid(),
          exportedAt: new Date(),
          user: auth.username,
          userId: auth.id,
          content: "",
          agency: auth.agency,
          type: downloadType,
          exportFrom: startDate.toDate(),
          exportTo: endDate.toDate(),
        },
      })
    );
  }
  return {
    ...results,
    blobURL,
    fileExtension: extension,
  };
};

interface ExportProgress {
  current: number;
  of: number;
}

const renderExportResults = (
  exportResult: ExportResult | undefined,
  exportError: Error | null
) => {
  if (exportError) {
    return (
      <Typography color="error">
        Unfortunately there was an unexpected error while processing your
        request and we were unable to create an export.
      </Typography>
    );
  }
  if (!exportResult) {
    return null;
  }
  const { blobURL, fileExtension } = exportResult;
  if (!blobURL || !fileExtension) {
    return (
      <Typography color="error">
        Unfortunately we were unable to generate an export file for these dates.
      </Typography>
    );
  }
  return (
    <>
      <Typography>
        Your export for dates {exportResult.startDate.toLocaleDateString()} to{" "}
        {exportResult.endDate.toLocaleDateString()} is ready for download:
      </Typography>
      <Button
        onClick={() => {
          const { blobURL, fileExtension, filePrefix } = exportResult;
          if (!blobURL || !fileExtension) {
            return;
          }
          FileSaver.saveAs(blobURL, `${filePrefix}.${fileExtension}`);
        }}
        variant="contained"
        color="primary"
      >
        Download export
      </Button>
    </>
  );
};

export const ReportsPage: BasePage = ({ auth }) => {
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: new Date(new Date().valueOf() - 86400 * 1000),
    endDate: new Date(),
  });
  const [downloads, setDownloads] = React.useState<Download[]>([]);
  const [downloadType, setDownloadType] = React.useState<DownloadType>(
    DownloadType.PARTY
  );
  const [airlines, setAirlines] = useState<Airline[]>([]);
  const [exportProgress, setExportProgress] = useState<ExportProgress>({
    current: 0,
    of: 1,
  });

  const mutateExports = useMutation<ExportResult, Error, ExportMetadata>(
    exportReport,
    {
      onSettled: () => {
        setExportProgress({ current: 0, of: 1 });
      },
    }
  );
  const { isLoading, data: exportResult, error: exportError } = mutateExports;

  useEffect(() => {
    const query = cms.getAirlines(true);
    if (query instanceof Promise) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      query.then((airlineRecords: GraphQLResult<object>) =>
        setAirlines(
          (
            airlineRecords?.data as Record<
              "listAirlines",
              Record<"items", Airline[]>
            >
          ).listAirlines.items
        )
      );
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = API.graphql(
      graphqlOperation(reportExtracts, {
        agency: auth.agency,
        sortDirection: "DESC",
        limit: 10,
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query.then((result: any) => {
      setDownloads(
        sortDownloads(
          result.data.reportExtractsByAgency.items.map(createDownload)
        )
      );
    });

    let downloadsUnsubscribe: () => void = () => undefined;
    const subscriptionResult = API.graphql(
      graphqlOperation(onCreateReportExtract)
    );
    if (subscriptionResult instanceof Observable) {
      //map((value) => value.value.data.onCreateReportExtract).filter((download) => download.agency === auth.agency)
      const sub = subscriptionResult
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item: any) => item.value.data.onCreateReportExtract)
        .filter((item) => item.agency === auth.agency)
        .map(createDownload)
        .subscribe({
          next: (value) => {
            setDownloads((downloads) => sortDownloads([...downloads, value]));
          },
        });
      downloadsUnsubscribe = () => sub.unsubscribe;
    }
    return downloadsUnsubscribe;
  }, []);

  const sortDownloads = (downloads: Download[]) =>
    downloads.sort((a, b) => b.exportedAt.valueOf() - a.exportedAt.valueOf());

  const classes = useStyles();
  const toggle = () => setOpen(!open);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDownloadTypeChange = (_: any, value: string) =>
    setDownloadType(value as DownloadType);

  const downloadTypeControl = (agency: string) => (
    <FormControl component="fieldset" className={classes.downloadType}>
      <FormLabel component="legend">Select your download type</FormLabel>
      <RadioGroup
        aria-label="download type"
        name="download type"
        value={downloadType}
        onChange={handleDownloadTypeChange}
        className={classes.typeRadioGroup}
      >
        <FormControlLabel
          value={DownloadType.PARTY}
          control={<Radio />}
          label="Party level data"
        />
        <FormControlLabel
          value={DownloadType.TRAVELLER}
          control={<Radio />}
          label="Traveller level data"
        />
        <FormControlLabel
          value={DownloadType.BOTH}
          control={<Radio />}
          label="Party + Individual (zipped)"
        />
        {agency === "NDS" && (
          <FormControlLabel
            value={DownloadType.ALL}
            control={<Radio />}
            label="All Types (zipped)"
          />
        )}
      </RadioGroup>
    </FormControl>
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={4} sm={12}>
        <Paper className={classes.paper}>
          {isLoading ? (
            <>
              <Typography variant="h5">
                Generating report, please wait...
              </Typography>
              <LinearProgress
                variant={
                  exportProgress.current === exportProgress.of
                    ? "indeterminate"
                    : "determinate"
                }
                value={normaliseProgress(
                  exportProgress.current,
                  exportProgress.of
                )}
              />
            </>
          ) : (
            <>
              <Typography variant="h5">
                Please select a date range for your export
              </Typography>
              <Typography paragraph onClick={toggle}>
                {(dateRange.startDate || new Date()).toLocaleDateString()} -{" "}
                {(dateRange.endDate || new Date()).toLocaleDateString()}
                <IconButton color="primary" aria-label="edit" component="span">
                  <EditIcon />
                </IconButton>
              </Typography>

              <DateRangePicker
                open={open}
                toggle={toggle}
                onChange={(range) => setDateRange(range)}
                initialDateRange={dateRange}
              />
              {auth.agency === "CQA" || auth.agency === "NDS"
                ? downloadTypeControl(auth.agency)
                : null}
              <Button
                onClick={() => {
                  mutateExports.mutate({
                    airlines,
                    auth,
                    dateRange,
                    downloadType,
                    statusCallback: (current, of) => {
                      setExportProgress({
                        current,
                        of,
                      });
                    },
                  });
                }}
                variant="contained"
                color="primary"
              >
                Create Export
              </Button>
              {exportResult || exportError ? (
                <>
                  <Divider className={classes.divider} />
                  {renderExportResults(exportResult, exportError)}
                </>
              ) : null}
            </>
          )}
        </Paper>
      </Grid>
      <Grid item lg={6} md={8} sm={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5">Exports Generated</Typography>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="previous exports"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Exported</TableCell>
                  <TableCell align="right">User</TableCell>
                  {auth.agency === "CQA" ? (
                    <TableCell align="right">Type</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {downloads.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.exportedAt.toLocaleString()}
                    </TableCell>
                    <TableCell align="right">{`${row.export_from.toLocaleDateString()} - ${row.export_to.toLocaleDateString()}`}</TableCell>
                    <TableCell align="right">{row.userName}</TableCell>
                    {auth.agency === "CQA" ? (
                      <TableCell align="right">{row.downloadType}</TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};
