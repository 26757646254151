/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import React, { Component } from "react";
import { v1 as uuid } from "uuid";

import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
} from "@material-ui/core";

import * as cms from "./active_cms";
import AddItem from "./AddItem";
import DelItem from "./DelItem";

const FlightFields = (id) => {
  return [
    {
      label: "Location",
      name: "location",
      id: "location",
      type: "text",
      required: true,
    },
    {
      label: "Number",
      name: "number",
      id: "number",
      type: "number",
      required: true,
    },
    {
      label: "",
      name: "id",
      id: "id",
      type: "hidden",
      required: true,
      value: id,
    },
    {
      label: "",
      name: "airlineID",
      id: "airlineID",
      type: "hidden",
      reference: true,
      required: true,
    },
  ];
};
const AirlineFields = (id) => {
  return [
    {
      label: "Name",
      name: "name",
      id: "name",
      type: "text",
      required: true,
    },
    {
      label: "Abbreviation",
      name: "abbreviation",
      id: "abbreviation",
      type: "text",
      required: true,
    },
    {
      label: "",
      name: "id",
      id: "id",
      type: "hidden",
      value: id,
      required: true,
    },
  ];
};
const DelFlightFields = [
  {
    name: "confirmation",
    label: "Are you sure you wish to remove the selected flight?",
    id: "confirmation",
    type: "checkbox",
    required: true,
  },
  {
    label: "id",
    name: "id",
    id: "id",
    type: "hidden",
    reference: true,
  },
];
const DelAirlineFields = [
  {
    name: "confirmation",
    label:
      "Are you sure you wish to remove the selected airline and its associated flights?",
    id: "confirmation",
    type: "checkbox",
    required: true,
  },
  {
    label: "id",
    name: "id",
    id: "id",
    type: "hidden",
    reference: true,
  },
];
class ListAirlines extends Component {
  handleSelectAirline = (event) => {
    this.setState({
      selectValue: event.target.value,
    });
    this.getFlights(event.target.value);
  };
  handleSelectFlight = (event) => {
    this.setState({
      flightValue: event.target.value,
      flightDelDisabled: false,
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menuItems: [],
      selectValue: "--",
      flightValue: "--",
      flightItems: [],
      flightDisabled: true,
      flightError: false,
      airlineError: false,
      flightDelDisabled: true,
    };
  }
  saveFlight = async (details) => {
    // TODO: ugh
    let refValue = details["referenceValue"];
    let refKey = details.fields.find((x) => x.reference === true).id;
    delete details[refKey];
    // details[refKey]=this.state.referenceValue;
    let nDetails = {
      ...details,
      [refKey]: refValue,
    };
    try {
      await cms.addFlight(nDetails);
      this.getFlights(details.airlineID);
      return true;
    } catch (e) {
      this.setState({
        flightError: true,
      });
      return false;
    }
  };
  delFlight = async (details) => {
    try {
      await cms.inactiveFlight(details.referenceValue);
      this.setState({
        flightDisabled: true,
        flightDelDisabled: true,
        flightValue: "--",
      });
      this.getFlights(details.airlineID);
      return true;
    } catch (e) {
      console.log(e);
      console.log("delete failed: %o", details);
      this.setState({
        airlineError: true,
      });
      return false;
    }
  };
  delAirline = async (details) => {
    let delFlights = this.state.flightItems.map((x) => x.id);
    try {
      await cms.deleteAirline(details.referenceValue);
      if (delFlights.length > 0) {
        try {
          await cms.removeFlights(delFlights);
        } catch (e) {
          console.log("could not remove flights");
          console.log(e);
        }
      }
      this.getItems("--");
      this.setState({
        flightDisabled: true,
      });
      return true;
    } catch (e) {
      console.log(e);
      console.log("delete failed: %o", details);
      this.setState({
        airlineError: true,
      });
      return false;
    }
  };
  saveAirline = async (details) => {
    try {
      let output = await cms.addAirline(details);
      this.getFlights(details.id);
      return true;
    } catch (e) {
      console.log(e);
      console.log("details failed: %o", details);
      this.setState({
        airlineError: true,
      });
      return false;
    }
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  componentDidMount = () => {
    this.getItems();
  };
  getFlights = (selected) => {
    if (!selected) selected = this.state.selectValue;
    cms.getFlightsByAirline(selected).then((data) => {
      this.setState({ flightItems: data.data.listFlights.items });
      if (data.data.listFlights.items.length === 0)
        this.setState({ flightValue: "--" });
      this.setState({ flightDisabled: false });
      this.getItems(selected);
    });
  };
  getItems = (selected) => {
    cms.getAirlines().then((data) => {
      this.setState({ menuItems: data.data.listAirlines.items });
      if (selected) this.setState({ selectValue: selected });
    });
  };
  render() {
    const {
      menuItems,
      selectValue,
      flightValue,
      flightItems,
      flightDisabled,
      flightDelDisabled,
      flightError,
      airlineError,
    } = this.state;
    return (
      <>
        <Grid item lg={5} md={6} sm={12}>
          <Card style={{ Padding: "11px" }}>
            <CardActionArea>
              <CardContent>
                <Typography variant="h5">Airlines</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Select an airline from the list to add a flight, or add a new
                  airline. You can also remove an airline along with its
                  associated flights.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  name="Airlines"
                  value={selectValue}
                  onChange={this.handleSelectAirline}
                >
                  <MenuItem value="--">Select an Airline</MenuItem>
                  {menuItems.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <AddItem
                  disabled={false}
                  itemName="Airline"
                  onSubmit={this.saveAirline}
                  formError={airlineError}
                  fields={AirlineFields(uuid())}
                />
                <DelItem
                  disabled={flightDisabled}
                  itemName="Airline"
                  onSubmit={this.delAirline}
                  formError={airlineError}
                  fields={DelAirlineFields}
                  referenceValue={selectValue}
                />
              </div>
            </CardActions>
          </Card>
        </Grid>
        <Grid item lg={5} md={6} sm={12}>
          <Card style={{ Padding: "11px" }}>
            <CardActionArea>
              <CardContent>
                <Typography variant="h5">Flights</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Add a new flight number to a selected airline. You can also
                  remove a flight from the list.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  name="Flights"
                  value={flightValue}
                  onChange={this.handleSelectFlight}
                  disabled={flightDisabled}
                >
                  <MenuItem value="--">Select a Flight</MenuItem>
                  {flightItems.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.number}/{item.location}
                      </MenuItem>
                    );
                  })}
                </Select>
                <AddItem
                  disabled={flightDisabled}
                  itemName="Flight"
                  onSubmit={this.saveFlight}
                  referenceValue={selectValue}
                  formError={flightError}
                  fields={FlightFields(uuid())}
                />
                <DelItem
                  disabled={flightDelDisabled}
                  itemName="Flight"
                  onSubmit={this.delFlight}
                  formError={airlineError}
                  fields={DelFlightFields}
                  referenceValue={flightValue}
                />
              </div>
            </CardActions>
          </Card>
        </Grid>
      </>
    );
  }
}

export default ListAirlines;
