import JapanCities from "./JapanCities";

/**
 *
 * @returns List of dict with label = 1900 till current year and value = 1900 till current year
 */
function generateYearsWithLabel() {
  var listYears = [];
  var currentYear = new Date().getFullYear();
  for (var year = currentYear; year >= 1900; year--) {
    listYears.push({ label: year.toString(), value: year.toString() });
  }
  return listYears;
}

const RussiaCities = [
  { value: "01Russia", label: "Moscow", parentValue: "19" },
  { value: "02Russia", label: "Khabarovsk", parentValue: "19" },
  { value: "03Russia", label: "Y-Sakhalinsk", parentValue: "19" },
  { value: "04Russia", label: "Irkutsk", parentValue: "19" },
  { value: "05Russia", label: "Vladivostok", parentValue: "19" },
  { value: "06Russia", label: "Other", parentValue: "19" },
];

const TaiwanCities = [
  { value: "01Taiwan", label: "Taipei", parentValue: "18" },
  { value: "02Taiwan", label: "Taichung", parentValue: "18" },
  { value: "03Taiwan", label: "Taoyuan", parentValue: "18" },
  { value: "04Taiwan", label: "Hsinchu", parentValue: "18" },
  { value: "05Taiwan", label: "Kaohsiung", parentValue: "18" },
  { value: "06Taiwan", label: "Other", parentValue: "18" },
];

const ChinaCities = [
  { value: "01China", label: "Shanghai", parentValue: "17" },
  { value: "02China", label: "Beijing", parentValue: "17" },
  { value: "03China", label: "Guangzhou", parentValue: "17" },
  { value: "04China", label: "Hangzhou", parentValue: "17" },
  { value: "05China", label: "Dalian", parentValue: "17" },
  { value: "06China", label: "Nanjing", parentValue: "17" },
  { value: "07China", label: "Harbin", parentValue: "17" },
  { value: "08China", label: "Zhengzhou", parentValue: "17" },
  { value: "09China", label: "Shenyang", parentValue: "17" },
  { value: "10China", label: "Chengdu", parentValue: "17" },
  { value: "11China", label: "Other", parentValue: "17" },
];

const KoreaCities = [
  { value: "01Korea", label: "Seoul", parentValue: "16" },
  { value: "02Korea", label: "Busan", parentValue: "16" },
  { value: "03Korea", label: "Incheon/Gyeonggi", parentValue: "16" },
  { value: "04Korea", label: "Daegu", parentValue: "16" },
  { value: "05Korea", label: "Daejeon", parentValue: "16" },
  { value: "06Korea", label: "Gwangju", parentValue: "16" },
  { value: "07Korea", label: "Ulsan", parentValue: "16" },
  { value: "08Korea", label: "Other", parentValue: "16" },
];

const heading =
  "![logo](/customs.png)\n\n# Guam Customs and Quarantine Agency\n\n";
const strings = {
  cities: {
    19: RussiaCities,
    18: TaiwanCities,
    17: ChinaCities,
    15: JapanCities,
    16: KoreaCities,
  },
  cityStrings: [
    ...RussiaCities,
    ...TaiwanCities,
    ...ChinaCities,
    ...JapanCities,
    ...KoreaCities,
  ],
  healthFormDescription:
    heading +
    "## Health Declaration Form\n\n## Each arriving traveler must submit a completed Health Declaration (one Declaration form per passenger is required). PLEASE COMPLETE THE FORM IN ENGLISH.",
  intendedResidentDescription: heading + "# Intended Resident",
  nameFieldLabel: "Your full name",
  firstNameLabel: "First",
  middleNameLabel: "Middle",
  lastNameLabel: "Last",
  packingListTitleLabel:
    heading +
    "## Declaration of Goods\n\nWARNING: The importation of controlled substances into Guam is a felony of the first degree (Title 9 GCA, Section 67). Violation of this section carries a mandatory prison sentence of up to ten (10) years. False statements made to a Customs Officer are punishable by law. All of your baggage, handbags and hand-carried articles may be opened and examined. If you have any questions about what must be reported or declared, ask a Customs Officer. To prevent the entry of dangerous agricultural pests or prohibited wildlife, the following are regulated and/or restricted: fruits, vegetables, plants, plant products, soil, meat, meat products, birds, snails and other live animals or animal products. Failure to declare such items may be subject to seizure and/or additional penalties. The Internal Revenue Laws of Guam require that you declare ALL goods, articles, or merchandise acquired abroad, whether worn or used, taxable or not, and whether obtained by purchase, as a gift or otherwise. Declare all goods or merchandise",
  personalGoodsLabel:
    "### Personal Goods:\n(Guam CQA to insert description/information on allowable amounts and limitations)",
  excisableGoodsLabel:
    "### Excisable Goods:\n(Guam CQA to insert description/information on allowable amounts and limitations)",
  bringingLabel: "I am (We are) bringing to Guam:",
  substancesLabel: "Controlled substances (prescription or otherwise)",
  firearmsLabel: "Firearms (personal owned)",
  explosivesLabel:
    "Explosives (commercial or consumer grade to include fireworks)",
  animalsLabel:
    "Animals or parts of animals or articles manufactured from wildlife",
  animalProductsLabel: "Animal products including meats, milk or eggs",
  plantsLabel:
    "Plants or parts of plants, including fresh fruits, vegetables, seeds flowers, or articles made of plant materials",
  soilLabel: "Soil materials or samples, or biological specimens",
  serviceAnimalLabel: "Live Service Animal",
  cigarettesLabel:
    "More than five (5) cartons of cigarettes (1,000 cigarettes) or twelve (12) packages of other tobacco products",
  alcoholLabel:
    "More than one (1) U.S. gallon/3.7 liters of alcoholic beverage per adult",
  welcomeText:
    heading +
    "# Håfa Adai! Thank you for choosing Guam as your next travel destination.\nFor your safety and convenience, we are implementing a digital Guam Customs Declaration Form for all arriving passengers to Guam. Please read the following information and complete the form to ensure compliance with Guam laws prior to your arrival.\nTo expedite the customs services, please indicate all the goods that you are bringing/carrying into Guam. Making false declarations in this form are punishable by law.\n\n[I AM READY TO FILL OUT THE FORM](/form/true)",
  visitorSpecificDescriptionLabel: heading + "# Visitor Information",
  onlineBookingLabel: "Did you book your travel arrangements online?",
  genderLabel: "Gender",
  ageLabel: "Age",
  emailLabel: "Email",
  promoLabel: "If you’d like to receive promotional info from GVB",
  descriptionLabel:
    heading +
    "# Personal Information\nEach arriving passenger or responsible family member must provide the following information, in English (only ONE declaration per family is required). The term “family” is defined as “members of a family residing in the same household who are related by blood, marriage, domestic relationship, or adoption.",
  todaysDateLabel: "Arrival Date – to Guam",
  transitLabel: "Transit",
  crewLabel: "Crew",
  airlineLabel: "Airline/Vessel",
  flightLabel: "Flight/Voyage No.",
  flightCityLabel: "Country/City",
  originatingLabel: "Originating From",
  dobLabel: "Birth Date",
  familyMembersLabel:
    "Total family members (including yourself) traveling with you",
  passportCountryLabel: "Country of Issuance",
  passportNoLabel: "Passport No.",
  // passportNoLabel: "Passport No./Drivers License/Alien Reg No./Sea Fearers ID",
  placeOfResidenceLabel: "Primary Place of Residence",
  arrivalInformationLabel: "## Arrival Information",
  countryOfOriginLabel: "Primary Place Of Residence",
  // countryOfOriginLabel: "Country of Origin (where do you live)",
  addressOnGuamLabel: "Address/Hotel Name (while on Guam)",
  statusLabel: "Status",
  transitFormDescription: heading + "# Transiting",
  residentFormDescription: heading + "# Returning Resident",
  returningReasonLabel: "Primary reason for trip just completed",
  transitingDestination: "Final Destination",
  guamStatusOptions: [
    { label: "Transiting", value: "transiting" },
    { label: "Flight Crew - Terminating", value: "crew/terminating" },
    { label: "Flight Crew - In Transit", value: "crew/in-transit" },
    { label: "Returning Resident", value: "resident" },
  ],
  statusOptions: [
    { label: "Visitor", value: "visitor" },
    { label: "Transiting", value: "transiting" },
    { label: "Flight Crew - Terminating", value: "crew/terminating" },
    { label: "Flight Crew - In Transit", value: "crew/in-transit" },
    { label: "Intended Resident", value: "intended_resident" },
    { label: "Returning Resident", value: "resident" },
  ],
  flights: {
    UA: [
      { label: "38/Narita", value: "38/Narita", parentValue: "UA" },
      { label: "95/CHUUK", value: "95/CHUUK", parentValue: "UA" },
      { label: "105/SAIPAN", value: "105/SAIPAN", parentValue: "UA" },
      { label: "116/HONG/KONG", value: "116/HONG/KONG", parentValue: "UA" },
      { label: "117/SAIPAN", value: "117/SAIPAN", parentValue: "UA" },
      { label: "132/CHUUK", value: "132/CHUUK", parentValue: "UA" },
      { label: "133/HONOLULU", value: "133/HONOLULU", parentValue: "UA" },
      { label: "136/NAGOYA", value: "136/NAGOYA", parentValue: "UA" },
      { label: "150/OSAKA", value: "150/OSAKA", parentValue: "UA" },
      { label: "154/CHUUK", value: "154/CHUUK", parentValue: "UA" },
      { label: "156/SAIPAN", value: "156/SAIPAN", parentValue: "UA" },
      { label: "158/KOROR", value: "158/KOROR", parentValue: "UA" },
      { label: "166/FUKUOKA", value: "166/FUKUOKA", parentValue: "UA" },
      { label: "172/NAGOYA", value: "172/NAGOYA", parentValue: "UA" },
      { label: "178/OSAKA", value: "178/OSAKA", parentValue: "UA" },
      { label: "182/SENDAI", value: "182/SENDAI", parentValue: "UA" },
      { label: "184/MANILA", value: "184/MANILA", parentValue: "UA" },
      { label: "186/YAP", value: "186/YAP", parentValue: "UA" },
      { label: "188/YAP", value: "188/YAP", parentValue: "UA" },
      { label: "192/KOROR", value: "192/KOROR", parentValue: "UA" },
      { label: "197/NARITA", value: "197/NARITA", parentValue: "UA" },
      { label: "201/HONOLULU", value: "201/HONOLULU", parentValue: "UA" },
      { label: "827/NARITA", value: "827/NARITA", parentValue: "UA" },
      { label: "874/NARITA", value: "874/NARITA", parentValue: "UA" },
    ],
    BX: [
      { label: "612/BUSAN", value: "612/BUSAN", parentValue: "BX" },
      { label: "614/BUSAN", value: "614/BUSAN", parentValue: "BX" },
      { label: "6123/BUSAN", value: "6123/BUSAN", parentValue: "BX" },
      { label: "6127/BUSAN", value: "6127/BUSAN", parentValue: "BX" },
      { label: "6615/KITAKYUSHU", value: "6615/KITAKYUSHU", parentValue: "BX" },
      { label: "6635/NARITA", value: "6635/NARITA", parentValue: "BX" },
    ],
    CI: [
      { label: "26/TAIPEI", value: "26/TAIPEI", parentValue: "CI" },
      { label: "2026/TAIPEI", value: "2026/TAIPEI", parentValue: "CI" },
      { label: "7859/HIROSHIMA", value: "7859/HIROSHIMA", parentValue: "CI" },
      { label: "7869/TAKAMATSU", value: "7869/TAKAMATSU", parentValue: "CI" },
      { label: "7885/OSAKA", value: "7885/OSAKA", parentValue: "CI" },
      { label: "7887/NARITA", value: "7887/NARITA", parentValue: "CI" },
    ],
    "5J": [{ label: "101/CEBU", value: "101/CEBU", parentValue: "5J" }],
    JL: [
      { label: "941/NARITA", value: "941/NARITA", parentValue: "JL" },
      { label: "8171/NARITA", value: "8171/NARITA", parentValue: "JL" },
      { label: "8941/NARITA", value: "8941/NARITA", parentValue: "JL" },
      { label: "8943/NARITA", value: "8943/NARITA", parentValue: "JL" },
      { label: "8945/NARITA", value: "8945/NARITA", parentValue: "JL" },
    ],
    TW: [
      { label: "301/INCHEON", value: "301/INCHEON", parentValue: "TW" },
      { label: "311/OSAKA", value: "311/OSAKA", parentValue: "TW" },
      { label: "337/NAGOYA", value: "337/NAGOYA", parentValue: "TW" },
      { label: "9235/NARITA", value: "9235/NARITA", parentValue: "TW" },
      { label: "9237/FUKUOKA", value: "9237/FUKUOKA", parentValue: "TW" },
      { label: "9301/INCHEON", value: "9301/INCHEON", parentValue: "TW" },
      { label: "9303/INCHEON", value: "9303/INCHEON", parentValue: "TW" },
      { label: "9311/OSAKA", value: "9311/OSAKA", parentValue: "TW" },
      { label: "9325/NARITA", value: "9325/NARITA", parentValue: "TW" },
      { label: "9337/NAGOYA", value: "9337/NAGOYA", parentValue: "TW" },
    ],
    PR: [{ label: "110/MANILA", value: "110/MANILA", parentValue: "PR" }],
    RS: [
      { label: "101/INCHEON", value: "101/INCHEON", parentValue: "RS" },
      { label: "102/INCHEON", value: "102/INCHEON", parentValue: "RS" },
      { label: "103/INCHEON", value: "103/INCHEON", parentValue: "RS" },
      { label: "105/INCHEON", value: "105/INCHEON", parentValue: "RS" },
      { label: "1011/INCHEON", value: "1011/INCHEON", parentValue: "RS" },
    ],
    "7C": [
      { label: "1182/NARITA", value: "1182/NARITA", parentValue: "7C" },
      { label: "3100/INCHEON", value: "3100/INCHEON", parentValue: "7C" },
      { label: "3102/INCHEON", value: "3102/INCHEON", parentValue: "7C" },
      { label: "3104/INCHEON", value: "3104/INCHEON", parentValue: "7C" },
      { label: "3106/INCHEON", value: "3106/INCHEON", parentValue: "7C" },
      { label: "3108/INCHEON", value: "3108/INCHEON", parentValue: "7C" },
      { label: "3110/INCHEON", value: "3110/INCHEON", parentValue: "7C" },
      { label: "3114/MUAN", value: "3114/MUAN", parentValue: "7C" },
      { label: "3154/BUSAN", value: "3154/BUSAN", parentValue: "7C" },
      { label: "3156/BUSAN", value: "3156/BUSAN", parentValue: "7C" },
      { label: "3162/INCHEON", value: "3162/INCHEON", parentValue: "7C" },
      { label: "3164/INCHEON", value: "3164/INCHEON", parentValue: "7C" },
      { label: "3172/OSAKA", value: "3172/OSAKA", parentValue: "7C" },
      { label: "3174/HIROSHIMA", value: "3174/HIROSHIMA", parentValue: "7C" },
      { label: "3178/OKAYAMA", value: "3178/OKAYAMA", parentValue: "7C" },
      { label: "3180/NAGOYA", value: "3180/NAGOYA", parentValue: "7C" },
      { label: "3182/FUKUOKA", value: "3182/FUKUOKA", parentValue: "7C" },
      { label: "3184/OSAKA", value: "3184/OSAKA", parentValue: "7C" },
      { label: "3186/SHIZUOKA", value: "3186/SHIZUOKA", parentValue: "7C" },
    ],
    KE: [
      { label: "111/INCHEON", value: "111/INCHEON", parentValue: "KE" },
      { label: "113/INCHEON", value: "113/INCHEON", parentValue: "KE" },
      { label: "2115/BUSAN", value: "2115/BUSAN", parentValue: "KE" },
      { label: "2913/INCHEON", value: "2913/INCHEON", parentValue: "KE" },
    ],
    LJ: [
      { label: "641/INCHEON", value: "641/INCHEON", parentValue: "LJ" },
      { label: "643/INCHEON", value: "643/INCHEON", parentValue: "LJ" },
      { label: "645/INCHEON", value: "645/INCHEON", parentValue: "LJ" },
      { label: "647/BUSAN", value: "647/BUSAN", parentValue: "LJ" },
      { label: "773/NARITA", value: "773/NARITA", parentValue: "LJ" },
      { label: "971/INCHEON", value: "971/INCHEON", parentValue: "LJ" },
      { label: "973/INCHEON", value: "973/INCHEON", parentValue: "LJ" },
      { label: "977/INCHEON", value: "977/INCHEON", parentValue: "LJ" },
    ],
    PV: [
      {
        label: "Vessel/Flight Number not applicable",
        value: "Vessel/Flight Number not applicable",
        parentValue: "PV",
      },
    ],
  },
  airlineOptions: [
    { label: "UNITED AIRLINES (UA)", value: "UA", parentValue: null },
    { label: "AIR BUSAN (BX)", value: "BX", parentValue: null },
    { label: "CHINA AIRLINES (CI)", value: "CI", parentValue: null },
    { label: "CEBU PACIFIC (5J)", value: "5J", parentValue: null },
    { label: "JAPAN AIRLINES (JL)", value: "JL", parentValue: null },
    { label: "T'WAY AIR (TW)", value: "TW", parentValue: null },
    { label: "PHILIPPINE AIRLINES (PR)", value: "PR", parentValue: null },
    { label: "AIR SEOUL (RS)", value: "RS", parentValue: null },
    { label: "JEJU AIR (7C)", value: "7C", parentValue: null },
    { label: "KOREAN AIR (KE)", value: "KE", parentValue: null },
    { label: "Private Flight (PV)", value: "PV", parentValue: null },
    { label: "JIN AIR (LJ)", value: "LJ", parentValue: null },
  ],
  countryOptions: [
    { label: "Guam", value: "01" },
    { label: "USA", value: "13" },
    { label: "Japan", value: "15" },
    { label: "Korea", value: "16" },
    { label: "China", value: "17" },
    { label: "Taiwan", value: "18" },
    { label: "Australia", value: "02" },
    { label: "CNMI", value: "03" },
    { label: "Europe", value: "04" },
    { label: "FSM", value: "05" },
    { label: "Hong Kong", value: "06" },
    { label: "India", value: "07" },
    { label: "Malaysia", value: "08" },
    { label: "Palau", value: "09" },
    { label: "Philippines", value: "10" },
    { label: "RMI", value: "11" },
    { label: "Singapore", value: "12" },
    { label: "Russia", value: "19" },
    { label: "Other", value: "Other" },
  ],
  flightOptions: [
    { label: "95", value: "95" },
    { label: "105", value: "105" },
    { label: "116", value: "116" },
    { label: "117", value: "117" },
    { label: "136", value: "136" },
    { label: "150", value: "150" },
    { label: "154", value: "154" },
    { label: "156", value: "156" },
    { label: "158", value: "158" },
    { label: "166", value: "166" },
    { label: "172", value: "172" },
    { label: "184", value: "184" },
    { label: "192", value: "192" },
    { label: "197", value: "197" },
    { label: "201", value: "201" },
    { label: "827", value: "827" },
    { label: "874", value: "874" },
    { label: "6123", value: "6123" },
    { label: "941", value: "941" },
    { label: "8943", value: "8943" },
    { label: "1182", value: "1182" },
    { label: "3102", value: "3102" },
    { label: "3154", value: "3154" },
    { label: "3184", value: "3184" },
    { label: "111", value: "111" },
    { label: "113", value: "113" },
    { label: "641", value: "641" },
  ],
  flightCityOptions: [
    { label: "Narita,Japan", value: "Narita,Japan" },
    { label: "Osaka,Japan", value: "Osaka,Japan" },
    { label: "Fukuoka,Japan", value: "Fukuoka,Japan" },
    { label: "Nagoya,Japan", value: "Nagoya,Japan" },
    { label: "Incheon/Seoul,SouthKorea", value: "Incheon/Seoul,SouthKorea" },
    { label: "Busan,SouthKorea", value: "Busan,SouthKorea" },
    { label: "Manila,Philippines", value: "Manila,Philippines" },
    { label: "Taipei,Taiwan", value: "Taipei,Taiwan" },
    { label: "Chuuk", value: "Chuuk" },
    { label: "Yap", value: "Yap" },
    { label: "Saipan", value: "Saipan" },
    { label: "Korror,Palau", value: "Korror,Palau" },
    { label: "HongKong", value: "HongKong" },
    { label: "Honolulu,Hawaii", value: "Honolulu,Hawaii" },
  ],
  years: generateYearsWithLabel(),
  months: [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ],
  days: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
  ],
  ages: [
    { value: "12 and under", label: "12 and under" },
    { value: "13-17 years", label: "13-17 years" },
    { value: "18-24 years", label: "18-24 years" },
    { value: "25-39 years", label: "25-39 years" },
    { value: "40-49 years", label: "40-49 years" },
    { value: "50-59 years", label: "50-59 years" },
    { value: "60+ years", label: "60+ years" },
  ],
  reasonOptions: [
    { label: "Pleasure/Vacation", value: "pleasure" },
    { label: "To get married", value: "married" },
    { label: "Honeymoon", value: "honeymoon" },
    { label: "Meeting/Conference", value: "conference" },
    { label: "Incentive Trip", value: "incentive" },
    { label: "Other Business", value: "business" },
    { label: "Visiting friends or relatives", value: "friends" },
    { label: "Government or Military", value: "military" },
    { label: "School Trip", value: "school" },
    { label: "Sports Event", value: "sports" },
    { label: "Medical", value: "Medical" },
    { label: "Other", value: "Other" },
  ],
  resaleTitle: heading + "# Commercial Merchandise",

  currencyVisitorCrewTitle: heading + "# Currency or Monetary Instrument",
  currencyVisitorCrewDescription:
    "The transportation of currency or monetary instruments, regardless of the amount, is legal. However, if you bring in or take out of the United States more than $10,000 (U.S. or foreign equivalent, or a combination of both), you are required by law to file a report on FinCEN 105 (formerly Customs Form 4790) with a Customs Officer. Monetary instruments include coin, currency, travelers checks and bearer instruments such as personal or cashiers checks and stocks and bonds. If you have someone else carry the currency or monetary instrument for you, you must also file a report on FinCEN 105. Failure to file the required report or failure to report the total amount that you are carrying may lead to the seizure of all the currency or monetary instruments, and may subject you to civil penalties and/or criminal prosecution.",
  // currencyVisitorCrewDescription: "The transportation of currency or monetary instruments, regardless of the amount, is legal. However, if you bring in or take out of the United States more than $10,000 (U.S. or foreign equivalent, or a combination of both), you are required by law to file a report on FinCEN 105 (formerly Customs Form 4790) with a Customs Officer. Monetary instruments include coin, currency, travelers checks and bearer instruments such as personal or cashiers checks and stocks and bonds. If you have someone else carry the currency or monetary instrument for you, you must also file a report on FinCEN 105. Failure to file the required report or failure to report the total amount that you are carrying may lead to the seizure of all the currency or monetary instruments, and may subject you to civil penalties and/or criminal prosecution.",
  over10kLabel:
    "I have (We have) currency of monetary instruments over $10,000 USD or foreign equivalent",
  resaleLabel: "I have (We have) commercial merchandise (goods for resale)",
  remainLabel:
    "VISITORS - The total value of all articles that will remain in Guam (commercial merchandise only; goods for resale) is: (US$)",
  declarationDescription:
    heading +
    "# Declaration of Goods\nThe Guam Customs and Quarantine Agency is responsible for protecting Guam against the illegal importation of prohibited items. Customs Officers have the authority to question and examine you and your personal property. If selected for examination, you will be treated in a courteous, professional, and dignified manner. The Customs Officer Supervisor or Officer-In-Charge on duty is available to answer your questions.\n\nWARNING: The importation of controlled substances into Guam is a felony of the first degree (Title 9 GCA, Section 67). Violation of this section carries a mandatory prison sentence of up to ten (10) years. False statements made to a Customs Officer are punishable by law. All of your baggage, handbags and hand-carried articles may be opened and examined. If you have any questions about what must be reported or declared, ask a Customs Officer. To prevent the entry of dangerous agricultural pests or prohibited wildlife, the following are restricted: fruits, vegetables, plants, plant products, soil, meat, meat products, birds, snails and other live animals or animal products. Failure to declare such items may be subject to seizure. The Internal Revenue Laws of Guam require that you declare ALL goods, articles, or merchandise acquired abroad, whether worn or used, taxable or not, and whether obtained by purchase, as a gift or otherwise. List all goods or merchandise.",
  declarationPreviousLabel:
    "In the previous page, you declared 'Yes’ to one of the below:",
  declarationDescriptionLabel:
    "Please describe the goods, or any additional goods you would like to declare.",
  consentDescriptionLabel:
    heading +
    "## Certification Statement\n\nThe Guam Customs and Quarantine Agency is responsible for protecting Guam against the importation of illegal and prohibited items. Customs Officers have the authority to question and examine you and your personal property. If selected for examination, you will be treated in a courteous, professional, and dignified manner. The Customs Officer Supervisor or Officer-In-Charge on duty is available to answer your questions.",
  consentCertifyFieldLabel:
    "I CERTIFY THAT I HAVE READ AND UNDERSTAND THE REQUIREMENTS ON THIS FORM AND CONSENT THAT ALL ORAL AND WRITTEN STATEMENTS WHICH I HAVE MADE ARE A TRUTHFUL DECLARATION.",
  declarationYes: "Yes",
  declarationNo: "No",
  lengthOfStayLabel: "Length of stay",
  lengthOptions: [
    { label: "Just a few hours layover", value: "layover" },
    { label: "For a day trip", value: "day_trip" },
    { label: "1 night", value: "1" },
    { label: "2 nights", value: "2" },
    { label: "3 nights", value: "3" },
    { label: "4 nights", value: "4" },
    { label: "5 nights", value: "5" },
    { label: "6 nights", value: "6" },
    { label: "7 – 10 nights", value: "7" },
    { label: "11 – 15 nights", value: "11" },
    { label: "16 – 30 nights", value: "16" },
    { label: "1 – 3 months", value: "month" },
    { label: "3 months – 1 year", value: "year" },
  ],
  tripDescription: "This trip to Guam is my:",
  tripOptions: [
    { label: "1st", value: "1" },
    { label: "2nd", value: "2" },
    { label: "3rd", value: "3" },
    { label: "4th", value: "4" },
    { label: "5th", value: "5" },
    { label: "6th or more", value: "6" },
  ],
  arrangementsLabel: "Travel Arrangements were made through a:",
  arrangementOptions: [
    { label: "Travel Agent", value: "agent" },
    { label: "Travel Website", value: "website" },
    { label: "Direct Booking (Airline/Accommodation)", value: "direct" },
  ],
  placeOfStayLabel: "Where will you stay while on Guam (mark all that apply)",
  stayOptions: [
    { label: "Hotel", value: "hotel" },
    { label: "Vacation Rental", value: "rental" },
    { label: "Cruise Ship", value: "ship" },
    { label: "Friends or Relatives", value: "friends" },
    { label: "Bed and Breakfast", value: "bandb" },
    { label: "Military Facility", value: "military" },
    { label: "Timeshare", value: "timeshare" },
    { label: "Other", value: "other" },
  ],
  secondaryCodes: [
    { value: "55", label: "Intelligence Report" },
    { value: "A", label: "Agriculture" },
    { value: "AK9", label: "Agriculture K9" },
    { value: "C", label: "Currency" },
    { value: "EPA", label: "Environmental Protection Agency" },
    { value: "FA", label: "Firearms" },
    { value: "FW", label: "Fish and Wildlife" },
    { value: "G", label: "General Inspection" },
    { value: "GPS", label: "Guam Product Seal" },
    { value: "INV", label: "Invoice" },
    { value: "IPR", label: "Intellectual Property Rights " },
    { value: "N", label: "Narcotics" },
    { value: "NK9", label: "Drug K9" },
    { value: "PH", label: "Public Health" },
    { value: "RT", label: "Revenue & Taxation" },
    { value: "UT", label: "Use Tax" },
    // { value:"EXIT",label:"EXIT" },
  ],
  agentCodes: [
    { label: "S", value: "S" },
    { label: "F", value: "F" },
  ],
  agentLocations: [
    { value: "Roving", label: "Roving" },
    { value: "K9", label: "K9" },
    { value: "Primary", label: "Primary" },
    { value: "Secondary", label: "Secondary" },
    { value: "Exit", label: "Exit" },
  ],
  symptoms: [
    { label: "Headache", name: "Headache" },
    { label: "Cough", name: "Cough" },
    { label: "Sore Throat", name: "SoreThroat" },
    { label: "Body Weakness", name: "BodyWeakness" },
    { label: "Joint / Muscle Pain", name: "JointMusclePain" },
    { label: "Bruising / Bleeding", name: "BruisingBleeding" },
    { label: "Rashes", name: "Rashes" },
    { label: "Red Eyes", name: "RedEyes" },
    { label: "Nausea", name: "Nausea" },
    { label: "Vomiting", name: "Vomiting" },
    { label: "Diarrhea", name: "Diarrhea" },
    {
      label: "Difficulty breathing / Shortness of breath",
      name: "DifficultybreathingShortnessofbreath",
    },
  ],
  healthQuestions: [
    {
      name: "healthWorker",
      label:
        "DID YOU VISIT ANY HEALTH WORKER, HOSPITAL, CLINIC OR NURSING HOME?",
    },
    {
      name: "poultryFarm",
      label: "DID YOU VISIT ANY POULTRY FARM OR ANIMAL MARKET?",
    },
    {
      name: "contactRespiratory",
      label:
        "HAVE YOU OR ANY OF YOUR FAMILY MEMBERS BEEN IN CLOSE CONTACT WITH A PERSON WHO HAS A FEVER, COUGH AND/OR RESPIRATORY PROBLEMS?",
    },
    {
      name: "sickFamilyGroup",
      label: "ANY OTHERS SICK WITHIN YOUR FAMILY/GROUP?",
    },
  ],
  medicationLabel: "LIST ANY MEDICATION TAKEN IN THE LAST 4-6 HRS:",
  fouo: "For Official Use Only",
  fouoDesc:
    "The use of this system and any information generated and/or accessed shall only be for official government use by officers of the Guam Customs & Quarantine Agency and any persons authorized by the Director or Chief of Customs. Any misuse shall be subject to disciplinary and/or criminal action.",
  travellerSearchExportNote:
    "All exports will be stored locally on the workstation you are using. To access the exported EDF, open the designated download folder.",
};

export { strings };
