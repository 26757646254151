import GetAppIcon from "@material-ui/icons/GetApp";
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Trip } from "./Trip";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

interface TravellerTableProps {
  onExport: (trips: Trip[]) => void;
  trips: Trip[];
  classes: ClassNameMap<string>;
  exporting: boolean;
}
export const TravellerTable: FunctionComponent<TravellerTableProps> = ({
  trips,
  classes,
  onExport,
  exporting,
}) => {
  const [numSelected, setNumSelected] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  useEffect(() => {
    setNumSelected(selected.length);
  }, [selected]);

  useEffect(() => {
    setRowCount(trips.length);
    setSelected([]);
  }, [trips]);

  const handleExport = () => {
    onExport(
      selected
        .map((id) => (trips || []).find((t) => t.id === id))
        .filter((t) => t) as Trip[]
    );
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = trips.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Box className={classes.paper}>
      <Typography variant="h5">Trips</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="Trips">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all trips" }}
                />
              </TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="right">Flight #</TableCell>
              <TableCell align="right">Traveller</TableCell>
              <TableCell align="right">Traveller #</TableCell>
              <TableCell align="right">Passport #/Traveller ID</TableCell>
              <TableCell align="right">Passenger Processing Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trips.map((row: Trip, index: number) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.travelDate.toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">{row.flight}</TableCell>
                  <TableCell align="right">{row.primaryTraveller}</TableCell>
                  <TableCell align="right">{row.travellerNumber}</TableCell>
                  <TableCell align="right">{row.passportNumber}</TableCell>
                  <TableCell align="right">{row.agentActions}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleExport}
        disabled={numSelected === 0 || exporting}
        endIcon={exporting ? <CircularProgress /> : <GetAppIcon />}
      >
        {exporting ? "" : "Export"}
      </Button>
    </Box>
  );
};
