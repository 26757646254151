export const buildListByCodeBase = (codebases) => {
  return /* GraphQL */ `
      query ListCustomerResponsesByCodeBase(${codebases
        .map((code, index) => "$code" + index + ": String")
        .join(",")}){
        ${codebases.map(
          (code, i) => /* GraphQL */ `
          _${code}: customerResponsesByCodeBase(QRCodeBase:$code${i}) { 
            ...CustomerResponse
          }`
        )}
      }
  
      fragment CustomerResponse on ModelCustomerResponseConnection {
        items{
          id
          QRCodeBase
          ArrivalDate
          ResidentForm
          ConsentForm
          CurrencyVisitorCrewForm
          CustomerForm
          DeclarationForm
          HealthForm1
          HealthForm2
          HealthForm3
          HealthForm4
          HealthForm5
          HealthForm6
          HealthForm7
          HealthForm8
          PackingList
          ResaleForm
          VisitorSpecificForm
          IntendedResident
          TransitForm
          createdAt
          updatedAt
          agentActions {
            items {
              action
              agent
              createdAt
              location
              secondary
            }
            nextToken
          }
          agentComments {
            items {
              id
              QRCodeBase
              PartyID
              agent
              comment
              agentToTraveller
              location
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    `;
};
