import React, { FunctionComponent, ReactNode } from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Switch, Route, Link, Redirect } from "react-router-dom";
import { AccountCircle } from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";
import { Auth } from "aws-amplify";
import {
  AllowedAgency,
  AuthConsumer,
  AuthContextValue,
} from "../contexts/AuthContext";

const drawerWidth = 240;

type ComponentBuilder = (auth: AuthContextValue) => () => ReactNode;
export const NullComponentBuilder: ComponentBuilder = () => () => null;
export interface MainMenuItem {
  text: string;
  path: string;
  icon?: JSX.Element;
  disabled?: boolean;
  parentItem?: string;
  container?: boolean;
  agencies: AllowedAgency | AllowedAgency[];
  type?: string;
  component: ComponentBuilder;
}

interface MiniDrawerProps {
  defaultOpen?: boolean;
  items: MainMenuItem[];
  subItems?: MainMenuItem[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const filterMenuItemByAuth = (auth: AuthContextValue) => (item: MainMenuItem) =>
  auth.agency !== "None" &&
  (item.agencies === "*" || item.agencies.indexOf(auth.agency) >= 0);

export const MiniDrawer: FunctionComponent<MiniDrawerProps> = ({
  defaultOpen,
  items,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(() => !!defaultOpen);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const avatarMenuOpen = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    Auth.signOut();
  };

  return (
    <AuthConsumer>
      {(auth: AuthContextValue) => {
        const menuItemFilter = filterMenuItemByAuth(auth);
        return (
          <div className={classes.root}>
            <CssBaseline />

            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap className={classes.title}>
                  Administration
                </Typography>
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={avatarMenuOpen}
                    onClose={handleClose}
                  >
                    {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                    <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                {items
                  .filter(menuItemFilter)
                  .filter(
                    (selectedItem) => selectedItem.parentItem === undefined
                  )
                  .map(({ text, path, icon, disabled }) => {
                    const children = items.filter(
                      (x) => x.parentItem === text && menuItemFilter(x)
                    );
                    const childrenOutput = children.map(
                      ({ text, path, icon, disabled }) => {
                        return (
                          <ListItem
                            button
                            key={text}
                            component={Link}
                            to={path}
                            disabled={disabled}
                          >
                            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                            <ListItemText primary={text} />
                          </ListItem>
                        );
                      }
                    );
                    if (children && children.length > 0) {
                      return (
                        <div key={text + "_holder"}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              {icon ? (
                                <ListItemIcon>{icon}</ListItemIcon>
                              ) : null}
                              <Typography>{text}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div key={text + "_children"}>
                                {childrenOutput.map((x) => x)}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    } else {
                      return (
                        <div key={text + "_holder"}>
                          <ListItem
                            button
                            key={text}
                            component={Link}
                            to={path}
                            disabled={disabled}
                          >
                            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                            <ListItemText primary={text} />
                          </ListItem>
                        </div>
                      );
                    }
                  })}
              </List>
              <Divider />
            </Drawer>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                {((firstItem?: MainMenuItem) => {
                  if (firstItem) {
                    return (
                      <Route exact path="/">
                        <Redirect to={firstItem.path} />
                      </Route>
                    );
                  }
                })(items.find(menuItemFilter))}

                {items.filter(menuItemFilter).map((item) => (
                  <Route
                    key={item.path}
                    path={item.path}
                    render={item.component(auth)}
                  />
                ))}
              </Switch>
              {/* {React.Children.only(children)} */}
            </main>
          </div>
        );
      }}
    </AuthConsumer>
  );
};
