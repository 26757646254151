import { Airline } from "../reportBuilders";
import { gvbRow } from "../reportBuilders/gvbExport";
import { partyRow } from "../reportBuilders/partyExport";
import { personRows } from "../reportBuilders/personExport";
import { ReportDataFilter, ReportType } from "./types";

import BlobStream, { IBlobStream } from "blob-stream";
import JSZip from "jszip";
import { healthRows } from "../reportBuilders/healthExport";

interface FileExport {
  blobURL: string;
  extension: string;
}

const onlyUnique = <T>(value: T, index: number, self: Array<T>) => {
  return self.indexOf(value) === index;
};

const travelerHeaderRow = `"FORM_ID","ARRIVAL_DATE","TRAVELLER_NUMBER","PASSPORT_NUMBER","PASSPORT_COUNTRY","FIRST_NAME","MIDDLE_NAME","LAST_NAME","DOB_YEAR","DOB_MONTH","DOB_DAY","GENDER","LANGUAGE","PARTY_ID","INSPECTION_NOTES"\n`;

const typeTitleRowMap: { [key in ReportType]: string } = {
  GVB: `""NCS Header","AIRLINE","FLIGHTNO","CHARTED","Language","Transit","04-Total Family","08-JAPAN","08-KOREA","08-CHINA","08-TAIWAN","08-RUSSIA","08-COUNTRY","Q.01","Q.02","Q.03","Q.04","Q.05","Q.06","Q.07","Q.08A-GENDER","Q.08A-AGE","Q.08B-GENDER","Q.08B-AGE","Q.08C-GENDER","Q.08C-AGE","Q.08D-GENDER","Q.08D-AGE","Q.08E-GENDER","Q.08E-AGE","Q.08F-GENDER","Q.08F-AGE","Q.08G-GENDER","Q.08G-AGE","Q.08H-GENDER","Q.08H-AGE"\n`,
  Party: `"FORM_ID","ARRIVAL_DATE","AIRLINE","FLIGHT","ORIGINATING_COUNTRY","ORIGINATING_CITY","RESIDENCE_COUNTRY","RESIDENCE_CITY","PARTY_SIZE","STATUS","LENGTH_OF_STAY","ADDRESS_ON_GUAM","FINAL_DESTINATION","TRIP_TO_GUAM","ONLINE_BOOKING","TRAVEL_ARRANGEMENTS","ACCOM_HOTEL","ACCOM_RENTAL","ACCOM_SHIP","ACCOM_FRIENDS","ACCOM_B_AND_B","ACCOM_MILITARY","ACCOM_TIMESHARE","ACCOM_OTHER","TRIP_REASON","EMAIL_ADDRESS","PHONE_NUMBER","DECLARE_SUBSTANCES","DECLARE_FIREARMS","DECLARE_EXPLOSIVES","DECLARE_ANIMALS","DECLARE_ANIMAL_PRODUCTS","DECLARE_PLANTS","DECLARE_SOIL","DECLARE_SERVICE_ANIMAL","DECLARE_CIGARETTES","DECLARE_ALCOHOL","DECLARE_DESCRIPTION","DECLARE_MONETARY_INSTRUMENTS","DECLARE_MERCHANDISE","DECLARE_MERCHANDISE_VALUE","CERTIFICATION","LANGUAGE","AGENT_0","POSITION_0","ACTION_0","REASON_0","AGENT_1","POSITION_1","ACTION_1","REASON_1","AGENT_2","POSITION_2","ACTION_2","REASON_2","AGENT_3","POSITION_3","ACTION_3","REASON_3","AGENT_4","POSITION_4","ACTION_4","REASON_4","AGENT_5","POSITION_5","ACTION_5","REASON_5","AGENT_6","POSITION_6","ACTION_6","REASON_6","PARTY_ID","INSPECTION_NOTES"\n`,
  Traveller: travelerHeaderRow,
  Health: travelerHeaderRow,
};

const typeRowMap: {
  [key in ReportType]: (
    airlines: Airline[],
    form: any
  ) => string | Array<string>;
} = {
  GVB: gvbRow,
  Party: partyRow,
  Traveller: personRows,
  Health: healthRows,
};

const stringsToCSV = (lines: string | Array<string>): string | null => {
  let newLines: string;
  if (Array.isArray(lines)) {
    if (lines.length === 0) {
      return null;
    }
    newLines = lines.join("\n");
  } else {
    if (lines === "") {
      return null;
    }
    newLines = lines;
  }
  return newLines.concat("\n");
};

const writeToBlobStream = async (blobStream: IBlobStream, data: string) => {
  const writeResult = blobStream.write(data);
  if (!writeResult) {
    await new Promise((resolve) => {
      blobStream.once("drain", resolve);
    });
  }
};

const createReportBlobStreams = async (types: Array<ReportType>) => {
  const reportTypesLength = types.length;
  const blobMap = new Map<ReportType, IBlobStream>();
  for (let i = 0; i < reportTypesLength; i++) {
    const currentReport = types[i];
    const stream = BlobStream();
    await writeToBlobStream(stream, typeTitleRowMap[currentReport]);
    blobMap.set(currentReport, stream);
  }
  return blobMap;
};

const createReportGenerator = async (
  airlines: Airline[],
  types: Array<ReportType> = ["Traveller"]
) => {
  const reportTypes = types.filter(onlyUnique);
  const reportTypesLength = reportTypes.length;

  const blobMap = await createReportBlobStreams(reportTypes);

  const postProcessor = async (
    filenamePrefix: string
  ): Promise<FileExport | null> => {
    if (reportTypesLength === 0) {
      return null;
    }
    if (reportTypesLength === 1) {
      const reportType = reportTypes[0];
      const blobStream = blobMap.get(reportType);
      if (!blobStream) {
        return null;
      }
      return {
        blobURL: blobStream.toBlobURL("text/csv"),
        extension: "csv",
      };
    }
    // Zip multiple files
    const zip = new JSZip();
    for (let i = 0; i < reportTypesLength; i++) {
      const currentReport = reportTypes[i];
      const currentBlob = blobMap.get(currentReport);
      if (!currentBlob) {
        return null;
      }
      zip.file(
        `${filenamePrefix}_${currentReport}.csv`,
        currentBlob.toBlob("text/csv")
      );
    }
    const zipBlob = await zip.generateAsync({
      compression: "DEFLATE",
      type: "blob",
      mimeType: "application/zip",
    });
    return {
      blobURL: URL.createObjectURL(zipBlob),
      extension: "zip",
    };
  };
  const writer = async (data: any) => {
    // Write data to reports
    for (let i = 0; i < reportTypesLength; i++) {
      const currentReport = reportTypes[i];
      const currentBlob = blobMap.get(currentReport);
      if (!currentBlob) {
        return false;
      }

      const rowGenerator = typeRowMap[currentReport];
      const lines = rowGenerator(airlines, data);
      const fileSegment = stringsToCSV(lines);
      if (fileSegment) {
        await writeToBlobStream(currentBlob, fileSegment);
      }
    }
    return true;
  };

  let complete = false;
  return {
    write: async (data: any): Promise<boolean> => {
      if (complete) {
        return false;
      }
      return await writer(data);
    },
    complete: async (filenamePrefix: string): Promise<FileExport | null> => {
      complete = true;
      return postProcessor(filenamePrefix);
    },
  };
};

export * from "./gql-data-fetcher";

export { createReportGenerator };
