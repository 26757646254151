/* eslint @typescript-eslint/no-explicit-any: "off" */
import React from "react";
import { I18n, ConsoleLogger as Logger } from "@aws-amplify/core";
import { VerifyContact } from "aws-amplify-react";
import {
  Button,
  Link,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { AuthWrapper, styles } from "./AuthWrapper";

const logger = new Logger("CustomVerifyContact");
class CustomVerifyContact extends VerifyContact {
  constructor(props: any) {
    super(props);
    this._validAuthStates = ["verifyContact"];
  }

  verifyView() {
    const user = this.props.authData;
    if (!user) {
      logger.debug("no user for verify");
      return <div></div>;
    }
    const { unverified } = user;
    if (!unverified) {
      logger.debug("no unverified on user");
      return <div></div>;
    }
    const { email, phone_number } = unverified;
    return (
      <RadioGroup
        aria-label="quiz"
        name="contact"
        onChange={this.handleInputChange}
      >
        {email ? (
          <FormControlLabel
            key="email"
            value="email"
            control={<Radio />}
            label={I18n.get("Email")}
          />
        ) : null}
        {phone_number ? (
          <FormControlLabel
            key="phone_number"
            value="phone_number"
            control={<Radio />}
            label={I18n.get("Phone Number")}
          />
        ) : null}
      </RadioGroup>
    );
  }

  submitView() {
    return (
      <TextField
        label={I18n.get("Code")}
        key="code"
        name="code"
        autoComplete="off"
        onChange={this.handleInputChange}
      />
    );
  }

  showComponent() {
    const { authData = {} } = this.props;
    const classesProps: any = this.props;
    const classes: any = classesProps.classes;

    return (
      <AuthWrapper
        subtitle={I18n.get(
          "Account recovery requires verified contact information"
        )}
        title={I18n.get("Admin Login")}
      >
        {this.state.verifyAttr ? this.submitView() : this.verifyView()}
        {this.state.verifyAttr ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.submit}
            type="submit"
          >
            {I18n.get("Submit")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.verify}
            type="submit"
          >
            {I18n.get("Verify")}
          </Button>
        )}
        <Link onClick={() => this.changeState("signedIn", authData)}>
          {I18n.get("Skip")}
        </Link>
      </AuthWrapper>
    );
  }
}

export default withStyles(styles)(CustomVerifyContact);
