/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import displayField from "./displayField";
import TextField from "./textField";
import { strings } from "./strings";

// eslint-disable-next-line
import MDTextField from "./mdTextField";
import MDTextFieldUI from "./mdTextFieldUI";
import MSONUIComponents from "mson-react/lib/components";

MSONUIComponents.MDTextField = MDTextFieldUI;

const Divider = TextField("\n***\n");

const healthInfo = (sequence = "", hidden = false) => {
  return [
    displayField("", `HealthName${sequence}`, "Full Name/Passport/ID No./DOB", {
      hidden: hidden,
    }),
    displayField("", `SeatNo${sequence}`, "Seat No.", { hidden: hidden }),
    displayField("", `Status${sequence}`, "Status", { hidden: hidden }),

    displayField(
      "",
      `HealthCities${sequence}`,
      "Countries Visited in the past 14 days",
      { hidden: hidden }
    ),
    displayField(
      "",
      `HealthUS${sequence}`,
      "Visited in the US in the past 14 days",
      { hidden: hidden }
    ),
    displayField(
      "",
      `HealthOther${sequence}`,
      "Other places visited in the past 14 days",
      { hidden: hidden }
    ),
    displayField("", `Address${sequence}`, strings.addressOnGuamLabel, {
      hidden: hidden,
    }),
    displayField("", `Telephone${sequence}`, "Telephone Number", {
      hidden: hidden,
      block: false,
    }),
    displayField("", `Email${sequence}`, "Email", { hidden: hidden }),
    displayField("", `Nationality${sequence}`, "Nationality", {
      hidden: hidden,
    }),
    displayField("", `Workplace${sequence}`, "Workplace", {
      hidden: hidden,
      block: false,
    }),
    displayField("", `Occupation${sequence}`, "Occupation", {
      hidden: hidden,
      block: true,
    }),

    displayField("", `Fever${sequence}`, "Fever (°F/C)", { hidden: hidden }),
    displayField(
      "",
      `Symptoms${sequence}`,
      "Symptoms at present or during the past 14 days",
      { hidden: hidden }
    ),
    displayField("", `OtherSymptoms${sequence}`, "Other symptoms", {
      hidden: hidden,
    }),
    // TextField("", `QuestionsField${sequence}`),
    {
      component: "MDTextField",
      name: `QuestionsField${sequence}`,
      value: "",
    },
    displayField("", `Medication${sequence}`, strings.medicationLabel, {
      hidden: hidden,
    }),
    {
      component: "Text",
      name: `Divider${sequence}`,
      text: `\n\n***\n`,
    },
  ];
};
const personalInfo = (sequence = "", hidden = false) => {
  return [
    displayField("", `SurnameField${sequence}`, "Family name", {
      block: false,
      hidden: hidden,
    }),
    displayField("", `FirstNameField${sequence}`, "First name", {
      block: false,
      hidden: hidden,
    }),
    displayField("", `MiddleNameField${sequence}`, "Middle", {
      block: false,
      hidden: hidden,
    }),
    displayField("", `DOBField${sequence}`, "Date of birth", {
      block: false,
      hidden: hidden,
    }),
    displayField("", `GenderField${sequence}`, "Gender", {
      block: true,
      hidden: hidden,
    }),
  ];
};
const customerForm = () => {
  return {
    fields: [
      TextField("Arrival Date"),
      displayField("", "ArrivalDateField", "MM/DD/YYYY", { block: false }),
      displayField("", "StatusField", "Status", { block: true }),
      displayField(
        "",
        "AirlineField",
        "Airline/Vessel & No. / Originating from",
        { block: true, fullWidth: true }
      ),
      // Divider,
      ...personalInfo("", false),
      displayField("", "Additional Label", "", {
        block: true,
        fullWidth: true,
      }),
      // Divider,
      displayField("", "AddressOnGuam", "Address while on Guam", {
        block: true,
        fullWidth: true,
      }),
      displayField(
        "",
        "PassportField",
        "Passport/ID Country / Passport No./ID No.",
        { block: true, fullWidth: true }
      ),
      displayField(
        "",
        "ResidenceField",
        "Primary place of residence (Country/City)",
        { block: true, fullWidth: true }
      ),
      TextField("I am (We are) bringing to guam:"),
      {
        component: "MDTextField",
        name: "PackingListField",
        value: "",
      },
      {
        component: "MDTextField",
        name: "DeclarationField",
        value: "",
      },
      TextField("### Visitor/Resident information"),
      displayField("", "StatusTextField", "I am a:", { block: false }), // 1
      displayField("", "LengthField", strings.lengthOfStayLabel, {
        block: false,
      }), // 2
      displayField("", "FinalDestination", strings.transitingDestination, {
        block: true,
      }), // 3
      displayField("", "TripField", strings.tripDescription, { block: true }), // 3
      displayField("", "OnlineBooking", strings.onlineBookingLabel, {
        block: true,
      }), // 4
      displayField("", "Arrangements", strings.arrangementsLabel, {
        block: true,
      }), // 5
      displayField("", "PlaceOfStay", strings.placeOfStayLabel, {
        block: true,
      }), // 6
      displayField("", "ReasonForTrip", "Primary reason for trip:", {
        block: true,
      }), // 7
      displayField("", "TotalLabel", strings.familyMembersLabel, {
        block: true,
      }),
      {
        component: "MDTextField",
        name: "TravelersLabel",
        value: "",
      },
      {
        component: "MDTextField",
        name: "Travelers",
        value: "",
      },
      displayField(
        "",
        "ConsentField",
        `"${strings.consentCertifyFieldLabel}"`,
        { block: true }
      ),
      displayField("", "EnteredAt", "Form submitted at:", { block: true }),
      Divider,
      TextField("\n## Health Form Information", "health-form-header"),
      ...healthInfo(1),
      ...healthInfo(2),
      ...healthInfo(3),
      ...healthInfo(4),
      ...healthInfo(5),
      ...healthInfo(6),
      ...healthInfo(7),
      ...healthInfo(8),
    ],
  };
};

export default customerForm;
