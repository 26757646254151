/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import { strings } from "./strings";

const lowerCase = (str) => {
  return str.toLowerCase();
};

const upperCase = (str) => {
  return str.toUpperCase();
};
const unCamelCase = (str) => {
  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
  str = str.toLowerCase(); //add space between camelCase text
  return str;
};
const properCase = (str) => {
  return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
};

const healthForm = (healthFormData) => {
  return healthFormData.map((formData) => {
    if (formData) {
      formData = JSON.parse(formData);
      const fields = Object.entries(formData);
      const countriesVisited = fields
        .filter((f) => f[0].includes("Visited"))
        .filter((v) => v[1] === true)
        .map((o) => {
          const visited = properCase(unCamelCase(o[0]));
          return visited.substr(visited.indexOf(" ") + 1);
        })
        .join(", "); // <-- comma separated country list
      const questions = strings.healthQuestions.map((s) => {
        // if(formData[s.name] === true) return s.label;
        return {
          question: `${s.label}`,
          answer: `${formData[s.name] === true ? "YES" : "NO"}`,
        };
      });

      const symptoms = strings.symptoms
        .map((s) => {
          let result;
          if (formData[s.name]) {
            result = s.label;
          }
          return result;
        })
        .filter((n) => n)
        .join(", ");
      return {
        countries: countriesVisited,
        name: formData["FormCounter"],
        visitedUs: formData["VisitedUS"],
        visitedOther: formData["VisitedOther"],
        status: formData["StatusField"].toUpperCase(),
        seat: formData["SeatNumberField"],
        address: formData["AddressOnGuam"],
        phone: formData["Telephone"],
        email: formData["EmailField"],
        nationality: formData["Nationality"],
        workplace: formData["WorkPlace"],
        job: formData["Occupation"],
        otherSymptoms: formData["Other"],
        symptoms: symptoms,
        medication: formData["recentMedication"],
        occupation: formData["Occupation"],
        fever: formData["Fever"],
        questions: questions,
      };
    } else {
      return {
        countries: null,
        name: null,
        visitedUs: null,
        visitedOther: null,
        status: null,
        seat: null,
        address: null,
        phone: null,
        email: null,
        nationality: null,
        workplace: null,
        job: null,
        otherSymptoms: null,
        symptoms: null,
        medication: null,
        occupation: null,
        fever: null,
        questions: [],
      };
    }
  });
};

export default healthForm;
