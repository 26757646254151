import React, { useEffect, useState } from "react";
import { Hub, Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

export interface AuthContextValue {
  id: string;
  state: string;
  user?: CognitoUser;
  agency: Agency;
  role: string;
  email: string;
  username: string;
  family_name: string;
  given_name: string;
}

export type Agency = "CQA" | "Health" | "GVB" | "NDS" | "None";
export type AllowedAgency = "*" | Agency;

const UnAuthedContextValue: AuthContextValue = {
  state: "unkown",
  id: "",
  user: undefined,
  agency: "None",
  role: "",
  email: "",
  username: "",
  family_name: "",
  given_name: "",
};

export const AuthContext: React.Context<AuthContextValue> =
  React.createContext<AuthContextValue>(UnAuthedContextValue);

const AUTHENTICATOR_AUTHSTATE = "amplify-authenticator-authState";

// eslint-disable-next-line
const createAuthContextValue = (userInfo: any): AuthContextValue => {
  let result: AuthContextValue;
  if (userInfo) {
    result = {
      state: "signIn",
      agency: userInfo.attributes["custom:agency"],
      email: userInfo.attributes.email,
      family_name: userInfo.attributes.family_name,
      given_name: userInfo.attributes.given_name,
      role: userInfo.attributes["custom:role"],
      user: userInfo,
      username: userInfo.username,
      id: userInfo.attributes.sub,
    };
  } else {
    result = UnAuthedContextValue;
  }
  return result;
};

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [auth, setAuth] = useState<AuthContextValue>(UnAuthedContextValue);
  useEffect(() => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        const userValues: AuthContextValue = createAuthContextValue(userInfo);
        localStorage.setItem(AUTHENTICATOR_AUTHSTATE, "signedIn");
        setAuth(userValues);
        return userValues;
      })
      .catch(() => localStorage.getItem(AUTHENTICATOR_AUTHSTATE))
      .then(
        (cachedAuthState) => cachedAuthState === "signedIn" && Auth.signOut()
      );
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      const { event, data } = payload;
      if (event === "signOut") {
        setAuth(Object.assign({}, UnAuthedContextValue, { state: event }));
        localStorage.removeItem(AUTHENTICATOR_AUTHSTATE);
        return;
      }
      localStorage.setItem(AUTHENTICATOR_AUTHSTATE, "signedIn");

      setAuth(createAuthContextValue(data));
    });
  }, []);
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;
