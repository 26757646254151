import { personRows } from "./personExport";
import { dataFilterer } from "./util";
import { Airline } from "./Airline";

export const healthRows = (airlines: Airline[], form: any): string[] => {
  if (!dataFilterer(form)) {
    return [];
  }
  return personRows(airlines, form);
};
