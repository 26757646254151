/* eslint @typescript-eslint/no-explicit-any: "off" */
import React from "react";
import { I18n } from "@aws-amplify/core";
import { RequireNewPassword } from "aws-amplify-react";
import { Button, Link, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { AuthWrapper, styles } from "./AuthWrapper";

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props: any) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
  }

  sendView() {
    return (
      <TextField
        required
        autoFocus={true}
        id="username"
        key="username"
        name="username"
        label={I18n.get("Username")}
        onChange={this.handleInputChange}
      />
    );
  }

  showComponent() {
    const classesProps: any = this.props;
    const classes: any = classesProps.classes;
    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    return (
      <AuthWrapper
        subtitle={I18n.get("Change Password")}
        title={I18n.get("Admin Login")}
      >
        <TextField
          required
          autoFocus={true}
          id="password"
          key="password"
          name="password"
          type="password"
          label={I18n.get("New Password")}
          onChange={this.handleInputChange}
        />

        {requiredAttributes.map((attribute: string) => (
          <TextField
            required
            label={I18n.get(convertToPlaceholder(attribute))}
            key={attribute}
            id={attribute}
            name={attribute}
            onChange={this.handleInputChange}
          />
        ))}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={this.change}
          type="submit"
        >
          {I18n.get("Change")}
        </Button>

        <Link onClick={() => this.changeState("signIn")}>
          {I18n.get("Back to Sign In")}
        </Link>
      </AuthWrapper>
    );
  }
}

function convertToPlaceholder(str: string) {
  return str
    .split("_")
    .map((part) => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(" ");
}

export default withStyles(styles)(CustomRequireNewPassword);
