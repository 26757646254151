/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchCustomerForms = /* GraphQL */ `
  query SearchCustomerForms(
    $filter: SearchCustomerFormsFilterInput
    $sort: SearchCustomerFormsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerForms(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        QRCodeBase
        PartyID
        ResidentForm
        ConsentForm
        CurrencyVisitorCrewForm
        CustomerForm
        DeclarationForm
        HealthForm1
        HealthForm2
        HealthForm3
        HealthForm4
        HealthForm5
        HealthForm6
        HealthForm7
        HealthForm8
        PackingList
        ResaleForm
        VisitorSpecificForm
        IntendedResident
        TransitForm
        createdAt
        updatedAt
        agentActions {
          id
          QRCodeBase
          action
          agent
          userId
          secondary
          location
          createdAt
          updatedAt
        }
        matches
      }
      nextToken
      total
    }
  }
`;
export const getCustomerResponse = /* GraphQL */ `
  query GetCustomerResponse($id: ID!) {
    getCustomerResponse(id: $id) {
      id
      QRCodeBase
      PartyID
      ArrivalDate
      ResidentForm
      ConsentForm
      CurrencyVisitorCrewForm
      CustomerForm
      DeclarationForm
      HealthForm1
      HealthForm2
      HealthForm3
      HealthForm4
      HealthForm5
      HealthForm6
      HealthForm7
      HealthForm8
      PackingList
      ResaleForm
      VisitorSpecificForm
      IntendedResident
      TransitForm
      createdAt
      updatedAt
      agentActions {
        items {
          id
          QRCodeBase
          action
          agent
          userId
          secondary
          location
          createdAt
          updatedAt
        }
        nextToken
      }
      agentComments {
        items {
          id
          QRCodeBase
          PartyID
          agent
          comment
          agentToTraveller
          location
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCustomerResponses = /* GraphQL */ `
  query ListCustomerResponses(
    $filter: ModelCustomerResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        QRCodeBase
        PartyID
        ArrivalDate
        ResidentForm
        ConsentForm
        CurrencyVisitorCrewForm
        CustomerForm
        DeclarationForm
        HealthForm1
        HealthForm2
        HealthForm3
        HealthForm4
        HealthForm5
        HealthForm6
        HealthForm7
        HealthForm8
        PackingList
        ResaleForm
        VisitorSpecificForm
        IntendedResident
        TransitForm
        createdAt
        updatedAt
        agentActions {
          items {
            id
            QRCodeBase
            action
            agent
            userId
            secondary
            location
            createdAt
            updatedAt
          }
          nextToken
        }
        agentComments {
          items {
            id
            QRCodeBase
            PartyID
            agent
            comment
            agentToTraveller
            location
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getReportExtract = /* GraphQL */ `
  query GetReportExtract($id: ID!) {
    getReportExtract(id: $id) {
      id
      exportedAt
      user
      userId
      content
      agency
      type
      exportFrom
      exportTo
      createdAt
      updatedAt
    }
  }
`;
export const listReportExtracts = /* GraphQL */ `
  query ListReportExtracts(
    $filter: ModelReportExtractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportExtracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        exportedAt
        user
        userId
        content
        agency
        type
        exportFrom
        exportTo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgentResponse = /* GraphQL */ `
  query GetAgentResponse($id: ID!) {
    getAgentResponse(id: $id) {
      id
      QRCodeBase
      action
      agent
      userId
      secondary
      location
      createdAt
      updatedAt
    }
  }
`;
export const listAgentResponses = /* GraphQL */ `
  query ListAgentResponses(
    $filter: ModelAgentResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        QRCodeBase
        action
        agent
        userId
        secondary
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgentComment = /* GraphQL */ `
  query GetAgentComment($id: ID!) {
    getAgentComment(id: $id) {
      id
      QRCodeBase
      PartyID
      agent
      comment
      agentToTraveller
      location
      createdAt
      updatedAt
    }
  }
`;
export const listAgentComments = /* GraphQL */ `
  query ListAgentComments(
    $filter: ModelAgentCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        QRCodeBase
        PartyID
        agent
        comment
        agentToTraveller
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCode = /* GraphQL */ `
  query GetCode($id: ID!) {
    getCode(id: $id) {
      id
      code
      createdAt
      updatedAt
    }
  }
`;
export const listCodes = /* GraphQL */ `
  query ListCodes(
    $filter: ModelCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecondary = /* GraphQL */ `
  query GetSecondary($id: ID!) {
    getSecondary(id: $id) {
      id
      number
      text
      active
      codeID
      code {
        id
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSecondarys = /* GraphQL */ `
  query ListSecondarys(
    $filter: ModelSecondaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecondarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        text
        active
        codeID
        code {
          id
          code
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerResponsesByCodeBase = /* GraphQL */ `
  query CustomerResponsesByCodeBase(
    $QRCodeBase: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerResponsesByCodeBase(
      QRCodeBase: $QRCodeBase
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        QRCodeBase
        PartyID
        ArrivalDate
        ResidentForm
        ConsentForm
        CurrencyVisitorCrewForm
        CustomerForm
        DeclarationForm
        HealthForm1
        HealthForm2
        HealthForm3
        HealthForm4
        HealthForm5
        HealthForm6
        HealthForm7
        HealthForm8
        PackingList
        ResaleForm
        VisitorSpecificForm
        IntendedResident
        TransitForm
        createdAt
        updatedAt
        agentActions {
          items {
            id
            QRCodeBase
            action
            agent
            userId
            secondary
            location
            createdAt
            updatedAt
          }
          nextToken
        }
        agentComments {
          items {
            id
            QRCodeBase
            PartyID
            agent
            comment
            agentToTraveller
            location
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customerResponsesByArrivalDate = /* GraphQL */ `
  query CustomerResponsesByArrivalDate(
    $ArrivalDate: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerResponsesByArrivalDate(
      ArrivalDate: $ArrivalDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        QRCodeBase
        PartyID
        ArrivalDate
        ResidentForm
        ConsentForm
        CurrencyVisitorCrewForm
        CustomerForm
        DeclarationForm
        HealthForm1
        HealthForm2
        HealthForm3
        HealthForm4
        HealthForm5
        HealthForm6
        HealthForm7
        HealthForm8
        PackingList
        ResaleForm
        VisitorSpecificForm
        IntendedResident
        TransitForm
        createdAt
        updatedAt
        agentActions {
          items {
            id
            QRCodeBase
            action
            agent
            userId
            secondary
            location
            createdAt
            updatedAt
          }
          nextToken
        }
        agentComments {
          items {
            id
            QRCodeBase
            PartyID
            agent
            comment
            agentToTraveller
            location
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const customerResponsesByPartyID = /* GraphQL */ `
  query CustomerResponsesByPartyID(
    $PartyID: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerResponsesByPartyID(
      PartyID: $PartyID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        QRCodeBase
        PartyID
        ArrivalDate
        ResidentForm
        ConsentForm
        CurrencyVisitorCrewForm
        CustomerForm
        DeclarationForm
        HealthForm1
        HealthForm2
        HealthForm3
        HealthForm4
        HealthForm5
        HealthForm6
        HealthForm7
        HealthForm8
        PackingList
        ResaleForm
        VisitorSpecificForm
        IntendedResident
        TransitForm
        createdAt
        updatedAt
        agentActions {
          items {
            id
            QRCodeBase
            action
            agent
            userId
            secondary
            location
            createdAt
            updatedAt
          }
          nextToken
        }
        agentComments {
          items {
            id
            QRCodeBase
            PartyID
            agent
            comment
            agentToTraveller
            location
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const reportExtractsByAgency = /* GraphQL */ `
  query ReportExtractsByAgency(
    $agency: String
    $exportedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportExtractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportExtractsByAgency(
      agency: $agency
      exportedAt: $exportedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        exportedAt
        user
        userId
        content
        agency
        type
        exportFrom
        exportTo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const agentResponsesByForm = /* GraphQL */ `
  query AgentResponsesByForm(
    $QRCodeBase: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentResponsesByForm(
      QRCodeBase: $QRCodeBase
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        QRCodeBase
        action
        agent
        userId
        secondary
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const agentCommentsByDate = /* GraphQL */ `
  query AgentCommentsByDate(
    $QRCodeBase: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAgentCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentCommentsByDate(
      QRCodeBase: $QRCodeBase
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        QRCodeBase
        PartyID
        agent
        comment
        agentToTraveller
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCustomerResponses = /* GraphQL */ `
  query SearchCustomerResponses(
    $filter: SearchableCustomerResponseFilterInput
    $sort: SearchableCustomerResponseSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerResponses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        QRCodeBase
        PartyID
        ArrivalDate
        ResidentForm
        ConsentForm
        CurrencyVisitorCrewForm
        CustomerForm
        DeclarationForm
        HealthForm1
        HealthForm2
        HealthForm3
        HealthForm4
        HealthForm5
        HealthForm6
        HealthForm7
        HealthForm8
        PackingList
        ResaleForm
        VisitorSpecificForm
        IntendedResident
        TransitForm
        createdAt
        updatedAt
        agentActions {
          items {
            id
            QRCodeBase
            action
            agent
            userId
            secondary
            location
            createdAt
            updatedAt
          }
          nextToken
        }
        agentComments {
          items {
            id
            QRCodeBase
            PartyID
            agent
            comment
            agentToTraveller
            location
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchReportExtracts = /* GraphQL */ `
  query SearchReportExtracts(
    $filter: SearchableReportExtractFilterInput
    $sort: SearchableReportExtractSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReportExtracts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        exportedAt
        user
        userId
        content
        agency
        type
        exportFrom
        exportTo
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getAirline = /* GraphQL */ `
  query GetAirline($id: ID!) {
    getAirline(id: $id) {
      id
      name
      abbreviation
      active
      createdAt
      updatedAt
      flights {
        items {
          id
          number
          location
          active
          airlineID
          createdAt
          updatedAt
          airline {
            id
            name
            abbreviation
            active
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listAirlines = /* GraphQL */ `
  query ListAirlines(
    $filter: ModelAirlineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAirlines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        abbreviation
        active
        createdAt
        updatedAt
        flights {
          items {
            id
            number
            location
            active
            airlineID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFlight = /* GraphQL */ `
  query GetFlight($id: ID!) {
    getFlight(id: $id) {
      id
      number
      location
      active
      airlineID
      createdAt
      updatedAt
      airline {
        id
        name
        abbreviation
        active
        createdAt
        updatedAt
        flights {
          items {
            id
            number
            location
            active
            airlineID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listFlights = /* GraphQL */ `
  query ListFlights(
    $filter: ModelFlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        location
        active
        airlineID
        createdAt
        updatedAt
        airline {
          id
          name
          abbreviation
          active
          createdAt
          updatedAt
          flights {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
