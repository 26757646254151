import React, { FunctionComponent } from "react";
import CustomSignIn from "./components/CustomSignIn";
import CustomForgotPassword from "./components/CustomForgotPassword";
import CustomRequireNewPassword from "./components/CustomRequireNewPassword";
import CustomVerifyContact from "./components/CustomVerifyContact";
import { AuthProvider } from "./contexts/AuthContext";
import {
  Authenticator,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
} from "aws-amplify-react";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import EditIcon from "@material-ui/icons/Edit";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import CropFreeIcon from "@material-ui/icons/CropFree";
import {
  MiniDrawer,
  MainMenuItem,
  NullComponentBuilder,
} from "./components/MiniDrawer";
import { BrowserRouter } from "react-router-dom";

import { AirlinesPage, CodesPage, ReportsPage, TravellerSearch } from "./pages";
import { QueryClientProvider, QueryClient } from "react-query";

// import "@aws-amplify/ui/dist/style.css";

const client = new QueryClient();

const menuItems: MainMenuItem[] = [
  {
    text: "CMS",
    path: "#",
    icon: <EditIcon />,
    agencies: "NDS",
    type: "parent",
    component: NullComponentBuilder,
  },
  {
    text: "Secondary Codes",
    path: "/codes",
    icon: <CropFreeIcon />,
    agencies: "NDS",
    parentItem: "CMS",
    component: (auth) => () => <CodesPage auth={auth} />,
  },
  {
    text: "Airlines",
    path: "/airlines",
    icon: <LocalAirportIcon />,
    agencies: "NDS",
    parentItem: "CMS",
    component: (auth) => () => <AirlinesPage auth={auth} />,
  },
  {
    text: "Report Extracts",
    path: "/reports",
    icon: <FlightLandIcon />,
    agencies: ["CQA", "GVB", "Health", "NDS"],
    component: (auth) => () => <ReportsPage auth={auth} />,
  },
  {
    text: "Traveller Search",
    path: "/search",
    icon: <PersonPinIcon />,
    agencies: ["CQA", "NDS"],
    component: (auth) => () => <TravellerSearch auth={auth} />,
  },
];
function App({ authState }: { authState?: string }) {
  if (authState === "signedIn") {
    return (
      <AuthProvider>
        <QueryClientProvider client={client}>
          <BrowserRouter>
            <MiniDrawer items={menuItems} defaultOpen={true} />
          </BrowserRouter>
        </QueryClientProvider>
      </AuthProvider>
    );
  } else {
    return null;
  }
}

interface AuthConfig {
  cognito: {
    REGION: string;
    USER_POOL_ID: string;
    APP_CLIENT_ID: string;
    IDENTITY_POOL_ID: string;
  };
}

interface AppWrapperProps {
  amplifyConfig: AuthConfig;
}

const AppWrapper: FunctionComponent<AppWrapperProps> = ({ amplifyConfig }) => {
  return (
    <Authenticator
      hide={[SignIn, ForgotPassword, RequireNewPassword, VerifyContact]}
      amplifyConfig={amplifyConfig}
    >
      <CustomSignIn />
      <CustomForgotPassword />
      <CustomRequireNewPassword />
      <CustomVerifyContact />

      <App />
    </Authenticator>
  );
};

export default AppWrapper;
