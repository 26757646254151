export interface Airline {
  id: string;
  name: string;
  abbreviation: string;
}

export const defaultAirline: Airline = {
  id: "-1",
  name: "UNKNOWN",
  abbreviation: "UNK",
};
