import React from "react";
import attach from "mson-react/lib/attach";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

class MDTextField extends React.PureComponent {
  render() {
    const { value, cssClassName } = this.props;
    return (
      <Typography component="div">
        <ReactMarkdown
          plugins={[gfm]}
          children={value}
          className={cssClassName}
        />
      </Typography>
    );
  }
}
const returnValue = attach(["value", "cssClassName"])(MDTextField);

export default returnValue;
