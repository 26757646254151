/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import Field from "mson/lib/fields/field";
import compiler from "mson/lib/compiler";

export default class MDTextField extends Field {
  _className = "MDTextField";

  _create(props) {
    super._create(props);

    this.set({
      schema: {
        component: "Form",
        fields: [
          {
            name: "required",
          },
          {
            name: "cssClassName",
          },
        ],
      },
    });
  }

  validate() {
    super.validate();
  }

  _setRequired(/* required */) {
    // Do nothing as we don't want to report any errors as this prop doesn't apply
  }
}

compiler.registerComponent("MDTextField", MDTextField);
