import { Grid } from "@material-ui/core";
import React from "react";

import ListAirlines from "../lib/ListAirlines";

const airlineList = <ListAirlines />;

import { BasePage } from "./BasePage";

export const AirlinesPage: BasePage = () => {
  return (
    <div>
      <Grid container spacing={3}>
        {airlineList}
      </Grid>
    </div>
  );
};
