/* eslint @typescript-eslint/no-explicit-any: "off" */
import React from "react";
import { I18n } from "@aws-amplify/core";
import { SignIn } from "aws-amplify-react";
import { Button, Link, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AuthWrapper, styles } from "./AuthWrapper";
import Alert from "@material-ui/lab/Alert";
class CustomSignInComponent extends SignIn {
  authToastMessage: string;

  constructor(props: any) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.authToastMessage = "";
  }

  /***
   * Over-rididing the triggerAuthEvent function from AuthPiece.tsx
   * Capturing the error message and assigning it to the class variable
   * Setting the showToast to false so that a Toast component is not created
   */
  triggerAuthEvent(event: any) {
    if (event.type === "error") {
      let errorMessage = event.data;
      switch (errorMessage) {
        case "Username/client id combination not found.":
          errorMessage = "Username not found.";
          break;
        case "Custom auth lambda trigger is not configured for the user pool.":
          errorMessage = "Incorrect username or password.";
          break;
        // If we get any other error, default to the following to avoid giving any hints to the actual error
        // to further avoid any brute force attempts
        default:
          errorMessage = "Incorrect username or password.";
          break;
      }

      this.authToastMessage = errorMessage;
    }

    const state = this.props.authState;
    if (this.props.onAuthEvent) {
      // setting showToast to false
      this.props.onAuthEvent(state, event, false);
    }
  }

  showComponent() {
    const classesProps: any = this.props;
    const classes: any = classesProps.classes;

    return (
      <>
        <AuthWrapper
          title={I18n.get("Admin Login")}
          subtitle={I18n.get("Sign in to your account")}
        >
          <TextField
            required
            autoFocus={true}
            id="username"
            key="username"
            name="username"
            label={I18n.get("Username")}
            onChange={this.handleInputChange}
          />
          <TextField
            required
            id="password"
            key="password"
            name="password"
            label={I18n.get("Password")}
            type="password"
            onChange={this.handleInputChange}
          />
          <Typography className={classes.typo}>
            {I18n.get("Forget your password? ")}
            <Link onClick={() => super.changeState("forgotPassword")}>
              {I18n.get("Reset password")}
            </Link>
          </Typography>

          <Button
            variant="contained"
            onClick={(e) => super.signIn(e)}
            color="primary"
            type="submit"
          >
            {I18n.get("Sign In")}
          </Button>
          {this.authToastMessage && (
            <Alert variant="outlined" severity="error">
              {this.authToastMessage}
            </Alert>
          )}
        </AuthWrapper>
        <Typography className={classes.warning}>
          WARNING - You are accessing an official government system and may only
          proceed if you are authorized by the Director or Chief of the Guam
          Customs and Quarantine Agency.
        </Typography>
      </>
    );
  }
}

export default withStyles(styles)(CustomSignInComponent);
