import { Airline } from "./Airline";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const personRows = (
  airlines: Airline[],
  form: Record<string, unknown>
): string[] => {
  const personData = getFormData<string>(
    form.CustomerForm as Record<string, string>,
    ""
  );

  const partyId = form.PartyID;
  const formId = form.QRCodeBase;
  const arrivalDate = form.ArrivalDate;

  // Casting form.agentComments.items as array, then filtering the array whene agentToTraveller is true,
  // then creating a map of comments and then joining by comma
  const agentComments = ((form.agentComments as { items: [] }).items as [])
    .filter((item: { agentToTraveller: boolean }) => item.agentToTraveller)
    .map((item: { comment: string }) => item.comment)
    .join(", ");

  const response = [...Array(8)].map((_: unknown, i: number) => {
    let result = "";
    const gender = personData("Gender", i);
    if (gender) {
      const language =
        ((form as { CustomerForm: { Language?: string } }).CustomerForm
          .Language as string) || "";
      result = `"${formId}","${arrivalDate}","${i + 1}","${personData(
        "PassportNoField",
        i
      )}","${personData("PassportCountryField", i)}","${personData(
        "FirstNameField",
        i
      )}","${personData("MiddleNameField", i)}","${personData(
        "LastNameField",
        i
      )}","${personData("DOBYearField", i)}","${personData(
        "DOBMonthField",
        i
      )}","${personData("DOBDayField", i)}","${personData(
        "Gender",
        i
      ).toLocaleUpperCase()}","${language}","${partyId}","${agentComments}"`;
    }
    return result;
  });
  return response.filter((row) => row);
};

const getSuffix = (i: number) => i || "";
function getFormData<T>(object: { [prop: string]: T }, fallback: T) {
  return (field: string, index: number) => {
    return object[`${field}${getSuffix(index)}`] || fallback;
  };
}
