import { Grid } from "@material-ui/core";
import React from "react";

import ListCodes from "../lib/ListCodes";

const codesList = <ListCodes />;

import { BasePage } from "./BasePage";

export const CodesPage: BasePage = () => {
  return (
    <div>
      <Grid container spacing={3}>
        {codesList}
      </Grid>
    </div>
  );
};
