/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
// Import gql helper and craft a GraphQL query
import { gql } from "graphql-tag";

import { API, graphqlOperation } from "aws-amplify";
import {
  createAirline,
  createFlight,
  createSecondary,
  updateSecondary,
  updateFlight,
  updateAirline,
} from "../graphql/mutations";
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

const deleteFlights = (flights) => {
  let deleteStatements = `
mutation deleteMultiple {\n`;
  flights.forEach((flight, idx) => {
    deleteStatements =
      deleteStatements +
      `
  id${idx + 1}: inactiveFlight(flight) {
    id
  }
`;
  });
  deleteStatements = `${deleteStatements}
}`;
  return gql(deleteStatements);
};

const FlightsQuery = (airline = "440") => {
  return /* GraphQL */ `
query MyQuery {
  listFlights(filter: {
    airlineID: {eq: "${airline}"},
    active: {ne: false}
  }) {
    items {
      airlineID
      id
      location
      number
    }
  }
}
`;
};

const CodesQuery = /* GraphQL */ `
  query CodesList {
    listSecondarys(filter: { active: { ne: false } }) {
      items {
        id
        number
        text
      }
    }
  }
`;
const AirlinesQuery = (includeInactive) => {
  const filterText = includeInactive ? "" : "(filter: {active: {ne: false }})";
  return `
query AirlinesList {
  listAirlines${filterText} {
    items {
      id
      name
      abbreviation
    }
  }
}
`;
};

export const getCodes = () => API.graphql(graphqlOperation(CodesQuery));
export const getAirlines = (includeInactive = false) =>
  API.graphql(graphqlOperation(AirlinesQuery(includeInactive)));
export const getFlightsByAirline = (airline) =>
  API.graphql(graphqlOperation(FlightsQuery(airline)));
export const deleteCode = (codeId) =>
  API.graphql(
    graphqlOperation(updateSecondary, { input: { id: codeId, active: false } })
  );
export const deleteAirline = (airlineId) =>
  API.graphql(
    graphqlOperation(updateAirline, { input: { id: airlineId, active: false } })
  );
export const addCode = (codeInformation) => {
  codeInformation["id"] = create_UUID();
  return API.graphql(
    graphqlOperation(createSecondary, {
      input: {
        number: codeInformation.number,
        id: codeInformation.id,
        text: codeInformation.text,
        codeID: "2",
      },
    })
  );
};
export const inactiveFlight = (flightId) =>
  API.graphql(
    graphqlOperation(updateFlight, { input: { id: flightId, active: false } })
  );
export const addAirline = (airlineInformation) =>
  API.graphql(
    graphqlOperation(createAirline, {
      input: {
        abbreviation: airlineInformation.abbreviation,
        id: airlineInformation.id,
        name: airlineInformation.name,
      },
    })
  );
export const addFlight = (flightInformation) =>
  API.graphql(
    graphqlOperation(createFlight, {
      input: {
        airlineID: flightInformation.airlineID,
        id: flightInformation.id,
        location: flightInformation.location,
        number: flightInformation.number,
      },
    })
  );

export const removeFlights = (flightIds) =>
  API.graphql(graphqlOperation(deleteFlights(flightIds)));
