import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import theme from "./theme";
import { Amplify } from "aws-amplify";

import config from "./config";
import awsConfig from "./aws-exports";
Amplify.configure(awsConfig);
ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App amplifyConfig={config} />
  </ThemeProvider>,
  document.querySelector("#root")
);
