const config = {
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_2BPq9qhVl",
    APP_CLIENT_ID: "5pt1rf3tn7sl0ulpshn373objb",
    IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID",
  },
};

export default config;
