/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { BasePage } from "./BasePage";
import { TravellerTable } from "./TravellerSearch/TravellerTable";
import html2pdf from "html2pdf.js";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Trip } from "./TravellerSearch/Trip";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";
import { countries, CountrySelect } from "../components/CountrySelect";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { agentResponsesByForm } from "../graphql/queries";
import { buildListByCodeBase } from "../graphql/queries_custom";
import { definition, getValues, setAttibutes } from "../lib/agent_form/mark";
import Component from "mson-react/lib/component";
import DateFnsUtils from "@date-io/date-fns";
import * as cms from "../lib/active_cms";
import moment from "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Airline } from "./reportBuilders/Airline";
import awsconfig from "../aws-exports";
import * as config from "../config.json";
import { strings } from "../lib/agent_form/strings";

Amplify.configure(awsconfig);
Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: "center",
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
    },
    fouo: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
      marginBottom: "25px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    table: {
      //   minWidth: 650,
    },
    typeRadioGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    downloadType: {
      margin: "0 auto",
    },
    button: {
      marginTop: "2em",
    },
    searchResults: {
      textAlign: "center",
    },
    spinner: {
      margin: "0 auto",
    },
    exportBox: {
      display: "none",
    },
    export: {
      "&:not(:first-child)": {
        breakBefore: "always",
        pageBreakBefore: "always",
      },
      '& [class*="MuiFormControl-root"], & [class*="MuiTypography-root"]': {
        breakInside: "avoid",
      },
      "& .agentActions table": {
        width: "90%",
      },
      "& .agentActions table tbody tr td:last-child": {
        wordBreak: "break-all",
      },
    },
  })
);

/**
 * @auth is handled in the custom request resolver
 */
const searchTrips = /* GraphQL */ `
  query SearchCustomerForms(
    $filter: SearchCustomerFormsFilterInput
    $sort: SearchCustomerFormsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerForms(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        QRCodeBase
        CustomerForm
        matches
      }
      nextToken
      total
    }
  }
`;

interface SearchOptions {
  FirstName?: string;
  LastName?: string;
  PassportNo?: string;
  PassportCountry?: string;
  Flight?: string;
  ArrivalDate?: {
    Date: string;
    Format: string;
    Timezone: string;
  };
}
const defaultSearch = {
  FirstName: "",
  LastName: "",
  PassportNo: "",
  PassportCountry: "",
  Flight: "",
};

export const TravellerSearch: BasePage = ({ auth }) => {
  const [searchTerm, setSearchTerm] = useState<SearchOptions>(defaultSearch);
  const [searched, setSearched] = useState(false);
  const [searching, setSearching] = useState(false);

  const [trips, setTrips] = useState<Trip[]>([]);
  const [exports, setExports] = useState<Record<string, unknown>[]>([]);
  const [airlines, setAirlines] = useState<Airline[]>([]);
  const [arrivalDate, setArrivalDate] = useState<Date | null>(null);
  const [exportProcessing, setExportProcessing] = useState<
    Promise<void> | undefined
  >();
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    exportProcessing
      ?.then(() => (exports.length ? Promise.resolve() : Promise.reject()))
      .then(() =>
        html2pdf()
          .set({
            html2canvas: { scrollX: 0, scrollY: 0 },
            pageBreak: { mode: ["css", "avoid-all"] },
            margin: 15,
          })
          .from(document.getElementById("ExportReports"))
          .toPdf()
          .get("pdf")
          .then((pdf: Record<string, any>) => {
            const totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(150);
              pdf.text(
                `PassengerExport_${moment(new Date()).format(
                  "yyyyMMDD_HHMM"
                )}.pdf - Page ${i} of ${totalPages}`,
                15,
                pdf.internal.pageSize.getHeight() - 10
              );
            }
          })
          .save(`PassengerExport_${moment(new Date()).format("yyyyMMDD_HHMM")}`)
      )
      .catch(() => undefined)
      .then(() => setExports([]))
      .then(() => setExportProcessing(undefined))
      .then(() => setExporting(false));
  }, [exportProcessing]);

  useEffect(() => {
    const query = cms.getAirlines(true);
    if (query instanceof Promise) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      query.then((airlineRecords: GraphQLResult<object>) =>
        setAirlines(
          (
            airlineRecords?.data as Record<
              "listAirlines",
              Record<"items", Airline[]>
            >
          ).listAirlines.items
        )
      );
    }
  }, []);

  const handleSearchInputChange = ({
    name,
    value,
  }: {
    name: string;
    value?:
      | string
      | {
          Date: string;
          Format: string;
          Timezone: string;
        };
  }) => {
    const newSearch = { ...searchTerm, [name]: value || "" };
    if (typeof value === "object" && value.Date === "Invalid date") {
      delete (newSearch as Record<string, unknown>)[name];
    }

    setSearchTerm(newSearch);
  };

  const classes = useStyles();

  const isButtonEnabled = () =>
    (searchTerm.ArrivalDate && searchTerm.ArrivalDate.Date) ||
    searchTerm.FirstName ||
    searchTerm.Flight ||
    searchTerm.LastName ||
    searchTerm.PassportCountry ||
    searchTerm.PassportNo;

  const handleSearch = async () => {
    setSearching(true);
    if (searchTerm === "") {
      setTrips([]);
    } else {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const wireSearchTerm = Object.entries(searchTerm)
          .filter(([, value]) => !!value)
          .reduce((agg, [key, value]) => ({ ...agg, [key]: value }), {});
        const responsesData = await API.graphql({
          query: searchTrips,
          variables: {
            filter: wireSearchTerm,
          },
        });
        const resps: any = responsesData;
        // setRawItems(resps.data.searchCustomerForms.items);
        const responses = resps.data.searchCustomerForms.items.map(
          (item: any) => ({
            QRCodeBase: item.QRCodeBase,
            CustomerForm: JSON.parse(item.CustomerForm),
            matches: item.matches.map(JSON.parse),
          })
        );

        const filterAgentResponse = {
          or: [{ action: { eq: "F" } }, { action: { eq: "S" } }],
        };

        // Query whether there is at least one agent response against the provided QR Code
        const queries = responses.map((res: any) =>
          API.graphql(
            graphqlOperation(agentResponsesByForm, {
              QRCodeBase: res.QRCodeBase,
              sortDirection: "DESC",
              limit: 1,
              filter: filterAgentResponse,
            })
          )
        );

        const formsItems = await Promise.all(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          queries as any[]
        ).then((responsesDataItems) =>
          responsesDataItems.reduce(
            (aggregate, responsesData) => [
              ...aggregate,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(responsesData as any).data.agentResponsesByForm.items,
            ],
            []
          )
        );

        const actionedQRCodeBases = formsItems.map((x: any) => x.QRCodeBase);

        setTrips(
          responses.map((r: any) => {
            const travellerNumber = r.matches[0].traveller;
            const fieldSuffix = travellerNumber ? travellerNumber : "";
            const name =
              r.CustomerForm[`FirstNameField${fieldSuffix}`] +
              (r.CustomerForm[`MiddleNameField${fieldSuffix}`]
                ? ` ${r.CustomerForm["MiddleNameField" + fieldSuffix]}`
                : "") +
              (r.CustomerForm[`LastNameField${fieldSuffix}`]
                ? ` ${r.CustomerForm["LastNameField" + fieldSuffix]}`
                : "");

            return {
              id: r.QRCodeBase,
              travelDate: new Date(r.CustomerForm.ArrivalDate),
              flight: r.CustomerForm.Flight,
              primaryTraveller: name,
              travellerNumber: travellerNumber + 1,
              passportNumber: r.CustomerForm[`PassportNoField${fieldSuffix}`],
              agentActions: actionedQRCodeBases.includes(r.QRCodeBase)
                ? "Y"
                : "N",
            };
          })
        );
      } catch (err) {
        console.log("error fetching responses", err);
      } finally {
        setSearching(false);
        setSearched(true);
      }
    }
  };

  const handleExport = async (selected: Trip[]) => {
    setExporting(true);
    // get agent actions because they don't come into the ES index
    const response: any = await API.graphql(
      graphqlOperation(
        buildListByCodeBase(selected.map((t) => t.id)),
        selected
          .map((t, i) => ({ [`code${i}`]: t.id }))
          .reduce((a, b) => ({ ...a, ...b }))
      )
    );

    const formsToExport = Object.values(response.data)
      .map(
        (entry: unknown) =>
          (entry as Record<"items", Record<"QRCodeBase", unknown>[]>).items[0]
      )
      .map((f: Record<"QRCodeBase", unknown>, i: number) => {
        const newDefinition = { ...definition, name: `app.MarkForm_${i}` };
        // newDefinition.fields = newDefinition.fields.map((field) => ({...field, name:`${field.name}_${i}`}));
        return { definition: newDefinition, form: f };
      });

    setExports(formsToExport);
  };

  const clearSearch = () => {
    setSearchTerm(defaultSearch);
    setArrivalDate(null);
    setTrips([]);
    setSearched(false);
  };

  const TripsTable = () => {
    let result = null;
    if (searching) {
      result = <CircularProgress className={classes.spinner} />;
    } else if (trips.length) {
      result = (
        <TravellerTable
          exporting={exporting}
          classes={classes}
          onExport={handleExport}
          trips={trips}
        />
      );
    } else if (searched) {
      result = (
        <Typography>
          No trips found, please refine your search criteria
        </Typography>
      );
    }
    if (result) {
      result = (
        <Grid item sm={12}>
          <Paper className={`${classes.paper} ${classes.searchResults}`}>
            {result}
          </Paper>
        </Grid>
      );
    }
    return result;
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={12}>
        <Paper className={classes.fouo}>
          <Typography variant="subtitle1">{strings.fouo}</Typography>
          <Typography variant="subtitle2">{strings.fouoDesc}</Typography>
          <Typography variant="subtitle1" style={{ whiteSpace: "pre-line" }}>
            {"\n"}Note
          </Typography>
          <Typography variant="subtitle2">
            {strings.travellerSearchExportNote}
          </Typography>
        </Paper>
        <Paper className={classes.paper}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Search for travellers</Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="First Name"
                  name="FirstName"
                  fullWidth={true}
                  onChange={({ target }) => handleSearchInputChange(target)}
                  value={searchTerm.FirstName}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Last Name"
                  name="LastName"
                  fullWidth={true}
                  onChange={({ target }) => handleSearchInputChange(target)}
                  value={searchTerm.LastName}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth={true}
                  name="PassportNo"
                  label="Passport Number"
                  onChange={({ target }) => handleSearchInputChange(target)}
                  value={searchTerm.PassportNo}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <CountrySelect
                  label="Passport Country"
                  fullWidth={true}
                  onChange={(_, newValue) =>
                    handleSearchInputChange({
                      name: "PassportCountry",
                      value: newValue ? newValue.code : undefined,
                    })
                  }
                  value={
                    searchTerm.PassportCountry
                      ? countries.find(
                          (c) => c.code === searchTerm.PassportCountry
                        )
                      : undefined
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth={true}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Arrival Date"
                    value={arrivalDate}
                    onChange={(date, value) => {
                      setArrivalDate(date);
                      const dateValue = moment.utc(value).tz(config.timezone);
                      handleSearchInputChange({
                        name: "ArrivalDate",
                        value: {
                          Date: dateValue.format("YYYY/MM/DD"),
                          Format: "yyyy/MM/dd",
                          Timezone: dateValue.format("Z"),
                        },
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={8}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  endIcon={<SearchIcon />}
                  fullWidth={true}
                  disabled={!isButtonEnabled()}
                  type="submit"
                >
                  Search Travellers
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="secondary"
                  onClick={clearSearch}
                  endIcon={<SearchIcon />}
                  fullWidth={true}
                  disabled={!isButtonEnabled()}
                >
                  Clear Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <TripsTable />
      <Box>
        {" "}
        {/*className={classes.exportBox}>*/}
        <Box id="ExportReports">
          {exports.map((data: any) => (
            <Box
              className={classes.export}
              key={`export_${data.form.QRCodeBase}`}
            >
              <Component
                key={data.QRCodeBase}
                definition={data.definition}
                onMount={({ component }: { component: any }) => {
                  if (data) {
                    setExportProcessing(
                      new Promise((resolve) => {
                        setAttibutes(component)(data.form);
                        const values = getValues(
                          data.form,
                          airlines,
                          auth.username
                        );
                        component.setValues(values);
                        setTimeout(resolve, 50);
                      })
                    );
                  }
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};
