/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */

import moment from "moment-timezone";
import lookup from "country-code-lookup";

import { strings } from "./strings";
import customerForm from "./customerForm";
import healthForm from "./health";
import * as config from "../../config.json";

const nonCrew = {
  transiting: {
    text: "Visitor in Transit",
    form: "TransitForm",
    reason: "", // I've made a huge mistake
  },
  visitor: {
    text: "Visitor to Guam",
    form: "VisitorSpecificForm",
    reason: "TripReasonField", // I've made a huge mistake
  },
  resident: {
    text: "Returning Guam resident",
    form: "ResidentForm",
    reason: "ReturningReason",
  },
  intended_resident: {
    text: "Intended resident moving to Guam for at least one year",
    form: "IntendedResident",
    reason: "TripReasonField",
  },
};

const packingStrings = {
  AlcoholField: strings.alcoholLabel,
  AnimalProductsField: strings.animalProductsLabel,
  AnimalsField: strings.animalsLabel,
  CigarettesField: strings.cigarettesLabel,
  ExplosivesField: strings.explosivesLabel,
  FirearmsField: strings.firearmsLabel,
  PlantsField: strings.plantsLabel,
  ServiceAnimalField: strings.serviceAnimalLabel,
  SoilField: strings.soilLabel,
  SubstancesField: strings.substancesLabel,
};
// ExcisableGoodsField: strings.excisableGoodsLabel,
// PersonalGoodsField: strings.personalGoodsLabel,

export const definition = {
  component: "Form",
  name: "app.MarkForm",
  fields: [
    {
      component: "MDTextField",
      name: "OverallHeader",
      value: "",
    },
    {
      component: "MDTextField",
      name: "AgentActions",
      value: "",
      cssClassName: "agentActions",
    },
    {
      component: "MDTextField",
      name: "AgentComments",
      value: "",
      cssClassName: "agentActions",
    },
    {
      component: "MDTextField",
      name: "Form Header",
      value: "### Form and Declaration",
      props: { class: "FormHeader" },
    },
    ...customerForm().fields,
  ],
};

export const getValues = (data, airlines, user) => {
  const customerFormData = JSON.parse(data["CustomerForm"]);
  const currencyValues = JSON.parse(data["CurrencyVisitorCrewForm"]);
  const resaleValues = JSON.parse(data["ResaleForm"]);
  const transitValues = JSON.parse(data["TransitForm"]);
  const consentValues = JSON.parse(data["ConsentForm"]);
  const packingList = JSON.parse(data["PackingList"]);
  const declarationValues = JSON.parse(data["DeclarationForm"]);

  const updates = {};
  updates.ConsentField = `${consentValues.ConsentField}`;
  updates.EnteredAt = moment
    .tz(data.createdAt, config.timezone)
    .toLocaleString();
  updates.OverallHeader = `
## Guam Customs Electronic Declaration Form
ID: ${data.QRCodeBase}

Extracted At: ${moment().tz(config.timezone).toLocaleString()}

Extracted By: ${user}
`;

  const agentActionsStrings = data.agentActions.items.length
    ? data.agentActions.items
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map(
          (action) =>
            `|${moment(action.createdAt)
              .tz(config.timezone)
              .toLocaleString()}|${action.agent}|${action.location}|${
              action.action
            }|${action.secondary.split(",").join(", ")}|`
        )
    : ["| | |----- None -----| | |"];
  const actionsTable = `### Passenger Processing Actions
|Timestamp|Agent|Location|Action|Secondary|
|:--------|:----|:-------|:-----|:--------|
${agentActionsStrings.join("\n")}`;
  updates.AgentActions = actionsTable;

  const agentCommentsStrings = data.agentComments.items.length
    ? data.agentComments.items
        .filter((comment) => comment.agentToTraveller)
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((comment) => {
          const cleanedComment = comment.comment.replaceAll(
            /\r?\n|\r|\|/g,
            " "
          );
          return `|${moment(comment.createdAt)
            .tz(config.timezone)
            .toLocaleString()}|${comment.agent}|${
            comment.location
          }|${cleanedComment}|`;
        })
    : ["| | |----- None -----| |"];
  const commentsTable = `### Inspection Notes
  |Timestamp|Agent|Location|Comment|
  |:--------|:----|:-------|:-----|
${agentCommentsStrings.join("\n")}`;
  updates.AgentComments = commentsTable;

  // pack up the health info
  let healthForms = [1, 2, 3, 4, 5, 6, 7, 8].map((i) => data[`HealthForm${i}`]);
  const HealthForm = healthForm(healthForms);
  HealthForm.forEach((form, i) => {
    i = i + 1;
    updates[`HealthName${i}`] = form["name"];
    updates[`HealthCities${i}`] = form["countries"];
    updates[`Address${i}`] = form["address"];
    updates[`Telephone${i}`] = form["phone"];
    updates[`Email${i}`] = form["email"];
    updates[`SeatNo${i}`] = form["seat"];
    updates[`Status${i}`] = form["status"]
      ? form["status"].toUpperCase().replace("_", " ")
      : null;
    updates[`HealthUS${i}`] = form["visitedUs"];
    updates[`HealthOther${i}`] = form["visitedOther"];
    updates[`Fever${i}`] = form["fever"];
    let countryString = "";
    try {
      countryString = lookup.byIso(form["nationality"]).country;
    } catch (e) {
      countryString = form["nationality"];
    }
    updates[`Nationality${i}`] = countryString;
    updates[`Workplace${i}`] = form["workplace"];
    updates[`Occupation${i}`] = form["occupation"];
    updates[`Symptoms${i}`] = form["symptoms"];
    updates[`OtherSymptoms${i}`] = form["otherSymptoms"];
    let QuestionsText = null;
    if (form["questions"] && form["questions"].length) {
      QuestionsText = `
|          |         |
|:---------|:--------|`;
      let questions = form["questions"]
        .map((q) => {
          return `|${q["question"]}|${q["answer"]}|`;
        })
        .join(`\n`);
      QuestionsText = `${QuestionsText}\n${questions}`;
    }
    updates[`QuestionsField${i}`] = QuestionsText;
    updates[`Medication${i}`] = form["medication"];
  });
  let packingText = "";
  Object.keys(packingList).forEach((key) => {
    let packingString = packingStrings[key];
    if (packingList[key] && key !== "CrewType") {
      packingText = `${packingText}\n* ${packingString}\n`;
    }
  });
  if (packingText === "") {
    packingText = "N/A\n\n";
  }
  packingText = `${packingText}\nDescription of goods: ${declarationValues["GoodsDescription"]}\n***\n`;
  updates.PackingListField = packingText;
  let TenKText = `
|   |       |  |
|:--|:------|:-|
|${strings.over10kLabel}|`;
  if (currencyValues["10KField"]) {
    TenKText = `
${TenKText}TRUE| |
`;
  } else {
    TenKText = `
${TenKText}FALSE| |
`;
  }
  TenKText = `
${TenKText}|${strings.resaleLabel}|`;

  if (resaleValues["ResaleField"]) {
    const resaleCurrency = new Intl.NumberFormat().format(
      resaleValues["RemainField"]
    );
    TenKText = `
${TenKText}TRUE| |
|Value: US$ ${resaleCurrency}|||
`;
  } else {
    TenKText = `
${TenKText}FALSE| |
`;
  }
  // ResaleText = `${ResaleText}\n***\n`
  updates["DeclarationField"] = TenKText; // +ResaleText;

  // TODO: table of people
  updates["TotalLabel"] = customerFormData["FamilyMembersField"];
  let travelerTable = "";
  if (customerFormData["LastNameField1"]) {
    updates.TravelersLabel = "Additional travelers covered by this form:";
    travelerTable = `
|Last Name|First Name|Middle|DOB|Gender|
|:--------|:---------|:-----|:--|:-----|`;
  } else {
    updates.TravelersLabel = "";
  }
  for (let i = 1; i < 8; i++) {
    if (customerFormData[`LastNameField${i}`]) {
      let DOBValue = `${customerFormData[`DOBDayField${i}`]}/${
        customerFormData[`DOBMonthField${i}`]
      }/${customerFormData[`DOBYearField${i}`]}`;
      travelerTable = `${travelerTable}\n|${
        customerFormData[`LastNameField${i}`]
      }|${customerFormData[`FirstNameField${i}`]}|${
        customerFormData[`MiddleNameField${i}`]
      }|${DOBValue}`;
      travelerTable = `${travelerTable}|${customerFormData[
        `Gender${i}`
      ].toUpperCase()}|`;
    }
  }
  updates["Travelers"] = travelerTable;
  // customer form stuff
  const originatingData = [
    customerFormData["OriginatingFromField"],
    customerFormData["OriginatingFromCityField"],
  ];

  const flightData = [customerFormData["Airline"], customerFormData["Flight"]]; // [airline,flightnumber]
  let flightAirline = "";
  flightAirline = airlines.find((v) => v.id === flightData[0]).name;

  const originatingCountry = lookup.byIso(String(originatingData[0])).country;
  const originatingCity = String(originatingData[1]);

  const ArrivalValues = moment
    .tz(String(customerFormData["ArrivalDate"]), config.timezone)
    .toArray();

  const ArrivalDateData = `${ArrivalValues[1] + 1}/${ArrivalValues[2]}/${
    ArrivalValues[0]
  }`;
  // const ArrivalDateData = `${ArrivalValues[2]}/${ArrivalValues[1]+1}/${ArrivalValues[0]}`;
  const HomeCountry = strings.countryOptions.find(
    (v) => v.value === customerFormData["CountryOfOriginField"]
  ).label;
  let CityValue = customerFormData["CountryOfOriginFieldCity"];
  if (strings.cityStrings.find((v) => v.value === CityValue))
    CityValue = strings.cityStrings.find((v) => v.value === CityValue);
  const HomeCity = CityValue ? "/" + CityValue.label : "";
  // set fields for customer form stuff
  updates["ArrivalDateField"] = ArrivalDateData;
  updates["StatusField"] = customerFormData["StatusField"]
    .toUpperCase()
    .replace("_", " ");
  updates[
    "AirlineField"
  ] = `${flightAirline}/${flightData[1]} / ${originatingCountry}/${originatingCity}`;
  updates["SurnameField"] = customerFormData["LastNameField"];
  updates["FirstNameField"] = customerFormData["FirstNameField"];
  updates["MiddleNameField"] = customerFormData["MiddleNameField"];
  updates["GenderField"] = customerFormData["Gender"].toUpperCase();
  updates[
    "DOBField"
  ] = `${customerFormData["DOBDayField"]}/${customerFormData["DOBMonthField"]}/${customerFormData["DOBYearField"]}`;
  updates["AddressOnGuam"] = customerFormData["AddressOnGuam"];
  updates["PassportField"] = `${
    lookup.byIso(customerFormData["PassportCountryField"]).country
  } / ${customerFormData["PassportNoField"]}`;
  updates["ResidenceField"] = `${HomeCountry}${HomeCity}`;
  if (!customerFormData["StatusField"].includes("crew/")) {
    // not a crew member - display visitor stuff
    // load the visitor data based on the status field using the nonCrew info above
    let formName = "";
    let visitorFormData = {};
    let statusValue = customerFormData["StatusField"];

    try {
      formName = nonCrew[statusValue].form;
      visitorFormData = JSON.parse(data[formName]);
    } catch (e) {
      console.warn(e);
    }

    // common fields for all non crew travelers
    updates["StatusTextField"] = nonCrew[statusValue].text;
    if (!statusValue.includes("transit")) {
      // I should have made the reason field have a common name. I am teh dumb.
      const reasonField = nonCrew[statusValue].reason;
      const reasonText = visitorFormData[reasonField]
        ? visitorFormData[reasonField]
        : "other";
      if (reasonText !== "other") {
        updates["ReasonForTrip"] = strings.reasonOptions.find(
          (o) => o.value === reasonText
        ).label;
      } else {
        updates["ReasonForTrip"] = "Other";
      }
    } else {
      updates["FinalDestination"] = transitValues["TransitFinalDestination"];
    }
    // fields for non-resident visitors
    // if not a resident
    if (!statusValue.includes("resident")) {
      const stayString = visitorFormData["LengthOfStayField"];
      let stayValue = "";
      if (stayString) {
        stayValue = strings.lengthOptions.find(
          (o) => o.value === stayString
        ).label;
      } else {
        stayValue = "N/A";
      }
      updates["LengthField"] = stayValue;

      const tripNumberString = visitorFormData["TripToGuamField"];
      let tripValue = "";
      if (tripNumberString) {
        tripValue = strings.tripOptions.find(
          (o) => o.value === tripNumberString
        ).label;
      } else {
        tripValue = "N/A";
      }
      updates["TripField"] = tripValue;

      updates["OnlineBooking"] = visitorFormData["onlineBooking"]
        ? visitorFormData["onlineBooking"]
        : "N/A";

      const arrangementsString = visitorFormData["TravelArrangementsField"];
      let arrangementsValue = "";
      if (arrangementsString) {
        arrangementsValue = strings.arrangementOptions.find(
          (o) => o.value === arrangementsString
        ).label;
      } else {
        arrangementsValue = "N/A";
      }
      updates["Arrangements"] = arrangementsValue;

      const places = [
        "bandb",
        "friends",
        "hotel",
        "military",
        "other",
        "rental",
        "ship",
        "timeshare",
      ];
      let placesString = "";
      places.forEach((place) => {
        const placeString = strings.stayOptions.find(
          (f) => f.value === place
        ).label;
        if (visitorFormData[`${place}Field`])
          placesString = `${placesString}, ${placeString}`;
      });
      if (placesString === "") {
        placesString = "N/A";
      } else {
        placesString = placesString.substring(1);
      }
      updates["PlaceOfStay"] = placesString;
    }
  }
  return updates;
};

export const setAttibutes = (component) => (data) => {
  for (let i = 1; i <= 8; i++) {
    if (!data[`HealthForm${i}`] || !data[`HealthForm${i}`]) {
      component.getField(`Divider${i}`).set({ hidden: true });
    }
  }

  component.getField("health-form-header").set({
    hidden: !Object.keys(data).filter(
      (k) => k.startsWith("HealthForm") && data[k]
    ).length,
  });

  return component;
};
