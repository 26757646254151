/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
const displayField = (value, name, label = null, props = null) => {
  let nComponent = {
    component: "TextField",
    value: `${value}`,
    name: name,
    disabled: true,
    editable: false,
  };
  if (label) nComponent.label = label;
  if (props)
    nComponent = {
      ...props,
      ...nComponent,
    };
  return nComponent;
};

export default displayField;
