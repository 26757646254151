/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */
import React, { Component } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
} from "@material-ui/core";

import * as cms from "./active_cms";
import AddItem from "./AddItem";
import DelItem from "./DelItem";

const CodeFields = [
  {
    label: "Text",
    name: "text",
    id: "text",
    type: "text",
    required: true,
  },
  {
    label: "Number",
    name: "number",
    id: "number",
    type: "text",
    required: true,
  },
];
const DelCodeFields = [
  {
    name: "confirmation",
    label: "Are you sure you wish to remove the selected code?",
    id: "confirmation",
    type: "checkbox",
    required: true,
  },
  {
    label: "id",
    name: "id",
    id: "id",
    type: "hidden",
    reference: true,
  },
];
class ListCodes extends Component {
  handleSelectCode = (event) => {
    this.setState({
      selectValue: event.target.value,
    });
    this.setState({ codeDisabled: false });
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menuItems: [],
      selectValue: "--",
      flightValue: "--",
      codeDisabled: true,
      flightError: false,
      codeError: false,
    };
  }
  delCode = async (details) => {
    try {
      await cms.deleteCode(details.referenceValue);
      this.getItems("--");
      return true;
    } catch (e) {
      console.log(e);
      console.log("delete failed: %o", details);
      this.setState({
        codeError: true,
      });
      return false;
    }
  };
  saveCode = async (details) => {
    try {
      let output = await cms.addCode(details);
      this.getItems(details.id);
      return true;
    } catch (e) {
      console.log(e);
      console.log("details failed: %o", details);
      this.setState({
        codeError: true,
      });
      return false;
    }
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  componentDidMount = () => {
    this.getItems();
  };
  getItems = (selected) => {
    cms.getCodes().then((data) => {
      this.setState({ menuItems: data.data.listSecondarys.items });
      if (selected) this.setState({ selectValue: selected });
    });
  };
  render() {
    const { menuItems, selectValue, codeError, codeDisabled } = this.state;
    return (
      <>
        <Grid item lg={5} md={6} sm={12}>
          <Card style={{ Padding: "11px" }}>
            <CardActionArea>
              <CardContent>
                <Typography variant="h5">Secondary Codes</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Select a code from the list to delete, or add a new code.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  name="Codes"
                  value={selectValue}
                  onChange={this.handleSelectCode}
                >
                  <MenuItem value="--">Select a Code</MenuItem>
                  {menuItems.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.number}-{item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
                <AddItem
                  disabled={false}
                  itemName="Code"
                  onSubmit={this.saveCode}
                  formError={codeError}
                  fields={CodeFields}
                />
                <DelItem
                  disabled={codeDisabled}
                  itemName="Code"
                  onSubmit={this.delCode}
                  formError={codeError}
                  fields={DelCodeFields}
                  referenceValue={selectValue}
                />
              </div>
            </CardActions>
          </Card>
        </Grid>
      </>
    );
  }
}

export default ListCodes;
