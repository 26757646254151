/* eslint @typescript-eslint/no-explicit-any: "off" */
import React from "react";
import { I18n } from "@aws-amplify/core";
import { ForgotPassword } from "aws-amplify-react";
import { Button, Link, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { AuthWrapper, styles } from "./AuthWrapper";
import Alert from "@material-ui/lab/Alert";

class CustomSignInComponent extends ForgotPassword {
  authToastMessage: string;

  constructor(props: any) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
    this.authToastMessage = "";
  }

  /***
   * Over-rididing the triggerAuthEvent function from AuthPiece.tsx
   * Capturing the error message and assigning it to the class variable
   * Setting the showToast to false so that a Toast component is not created
   */
  triggerAuthEvent(event: any) {
    if (event.type === "error") {
      let errorMessage = event.data;
      if (errorMessage === "Username/client id combination not found.") {
        errorMessage = "Username not found.";
      }

      this.authToastMessage = errorMessage;
    }

    const state = this.props.authState;
    if (this.props.onAuthEvent) {
      // setting showToast to false
      this.props.onAuthEvent(state, event, false);
    }
  }

  sendView() {
    return (
      <TextField
        required
        autoFocus={true}
        id="username"
        key="username"
        name="username"
        label={I18n.get("Username")}
        onChange={this.handleInputChange}
      />
    );
  }

  submitView() {
    const classesProps: any = this.props;
    const classes: any = classesProps.classes;
    return (
      <div className={classes.form}>
        <TextField
          required
          autoComplete="off"
          id="code"
          key="code"
          name="code"
          label={I18n.get("Code")}
          onChange={this.handleInputChange}
        />

        <TextField
          required
          id="password"
          key="password"
          name="password"
          label={I18n.get("New Password")}
          autoComplete="off"
          type="password"
          onChange={this.handleInputChange}
        />
      </div>
    );
  }

  showComponent() {
    const { authData = {} } = this.props;
    const classesProps: any = this.props;
    const classes: any = classesProps.classes;

    return (
      <AuthWrapper
        subtitle={I18n.get("Reset your password")}
        title={I18n.get("Admin Login")}
      >
        {this.state.delivery || authData.username
          ? this.submitView()
          : this.sendView()}
        {this.state.delivery || authData.username ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={this.submit}
            type="submit"
          >
            {I18n.get("Submit")}
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={this.send}
            type="submit"
          >
            {I18n.get("Send Code")}
          </Button>
        )}

        {this.authToastMessage && (
          <Alert variant="outlined" severity="error">
            {this.authToastMessage}
          </Alert>
        )}

        {this.state.delivery || authData.username ? (
          <Link onClick={this.send}>{I18n.get("Resend Code")}</Link>
        ) : (
          <Link onClick={() => this.changeState("signIn")}>
            {I18n.get("Back to Sign In")}
          </Link>
        )}
      </AuthWrapper>
    );
  }
}

export default withStyles(styles)(CustomSignInComponent);
